import request from '@/utils/request';
import request2 from '@/utils/request2';
import { refreshToken } from '@/api/login';
import { getToken, getRefreshToken, setToken, setRefreshToken, removeToken, removeRefreshToken, removeUserName, removeUser, getUser } from '@/utils/auth'
import { ElNotification, ElMessageBox, ElMessage, ElLoading } from 'element-plus'

export function apiCommon(params, callback,errback) {
  return request(params).then((res) => {
    callback(res);
  }).catch((error) => {
    try{
      let status = error.response.status;
      if (status == 401) {
        refreshToken().then(res => {
          setToken(res.api_token);
          setRefreshToken(res.refresh_token);
          params.data.retry = 1;
          //console.log('retry api call');
          //console.log(params);
          request(params).then((res2) => {
            callback(res2);
          });
        }, error => {
          let status = error.response.status
          if (status == 401) {
            const userInfo=JSON.parse(getUser());
            let url = "/login";
            if (userInfo.view_user == 1)
            {
              url = "/viewer_login";
            }
            removeToken();
            removeRefreshToken();
            removeUserName();
            removeUser();
            ElMessage({
              icon: null,
              message: "セッションが終了しました。 再度ログインしてください",
              type: 'error',
              duration: 2 * 1000,
              showClose: true
            })

            setTimeout(() => {
              location.href = url;
            }, 2000);

          }

        });
      }else{
        errback(error.response);
      }
    }catch(err){
      errback();
    }

  });
}

// project
export function queryProjectList(data, callback,errback) {
  apiCommon({
    url: '/project_list',
    method: 'post',
    data
  }, callback,errback);
}

export function selectProject(data, callback,errback) {
  apiCommon({
    url: '/select_project',
    method: 'post',
    data
  }, callback,errback);
}

export function createProject(data, callback,errback) {
  apiCommon({
    url: '/create_project',
    method: 'post',
    data
  }, callback,errback);
}

export function getProjectInfo(data, callback,errback) {
  apiCommon({
    url: '/project_info',
    method: 'post',
    data
  }, callback,errback);
}

export function editProject(data, callback,errback) {
  apiCommon({
    url: '/edit_project',
    method: 'post',
    data
  }, callback,errback);
}

export function deleteProject(data, callback,errback) {
  apiCommon({
    url: '/delete_project',
    method: 'post',
    data
  }, callback,errback);
}

export function queryPjid(data, callback,errback) {
  apiCommon({
    url: '/get_pjid',
    method: 'post',
    data
  }, callback,errback);
}

export function projectInvite(data, callback,errback){
  apiCommon({
    url: '/project/invite',
    method: 'post',
    data
  }, callback,errback);
}

export function projectSubInvite(data, callback,errback){
  apiCommon({
    url: '/project/sub_invite',
    method: 'post',
    data
  }, callback,errback);
}

export function withdrawalEvent(data, callback,errback){
  apiCommon({
    url: '/withdrawal',
    method: 'post',
    data
  }, callback,errback);
}

export function projectSubClear(data, callback,errback){
  apiCommon({
    url: '/project/sub_clear',
    method: 'post',
    data
  }, callback,errback);
}

//class
export function queryClassList(data, callback,errback) {
  apiCommon({
    url: '/class_list',
    method: 'post',
    data
  }, callback,errback);
}

export function createClass(data, callback,errback) {
  apiCommon({
    url: '/create_class',
    method: 'post',
    data
  }, callback,errback);
}

export function editClass(data, callback,errback) {
  apiCommon({
    url: '/edit_class',
    method: 'post',
    data
  }, callback,errback);
}

export function deleteClass(data, callback,errback) {
  apiCommon({
    url: '/delete_class',
    method: 'post',
    data
  }, callback,errback);
}

//result
export function queryPersonInfo(data, callback,errback) {
  apiCommon({
    url: '/results/person',
    method: 'post',
    data
  }, callback,errback);
}

export function getPersonInfoList(data, callback,errback) {
  apiCommon({
    url: '/results/persons',
    method: 'post',
    data
  }, callback,errback);
}

export function queryEventImageList(data, callback,errback) {
  apiCommon({
    url: '/results/event_images',
    method: 'post',
    data
  }, callback,errback);
}

export function eventImageChangeSelect(data, callback,errback) {
  apiCommon({
    url: '/results/event_image/change_select',
    method: 'post',
    data
  }, callback,errback);
}

export function removeEventImage(data, callback,errback) {
  apiCommon({
    url: '/results/event_image/remove',
    method: 'post',
    data
  }, callback,errback);
}

export function queryEventImageInfo(data, callback,errback) {
  apiCommon({
    url: '/results/event_image',
    method: 'post',
    data
  }, callback,errback);
}

export function queryResultPersonList(data, callback,errback) {
  apiCommon({
    url: '/results/person_list',
    method: 'post',
    data
  }, callback,errback);
}

export function queryAlbumResultPersonList(data, callback,errback) {
  apiCommon({
    url: '/album_page/result_list ',
    method: 'post',
    data
  }, callback,errback);
}

export function queryResultsDownload(data, callback,errback) {
  apiCommon({
    url: '/results/download',
    method: 'post',
    data
  }, callback,errback);
}

export function createViewAccount(data, callback,errback) {
  apiCommon({
    url: '/results/create_view_account',
    method: 'post',
    data
  }, callback,errback);
}

export function changeFaceStatus(data, callback,errback) {
  apiCommon({
    url: '/results/change_face_status',
    method: 'post',
    data
  }, callback,errback);
}

export function queryEventGroups(data, callback,errback) {
  apiCommon({
    url: '/results/event_groups',
    method: 'post',
    data
  }, callback,errback);
}
export function albumPagePersons(data,callback,errback){
  apiCommon({
    url: '/album_page/persons',
    method: 'post',
    data
  }, callback,errback);
}

export function albumPageList(data,callback,errback){
  apiCommon({
    url: '/album_page_list',
    method: 'post',
    data
  }, callback,errback);
}

export function albumPageEventImages(data,callback,errback){
  apiCommon({
    url: '/album_page/event_images',
    method: 'post',
    data
  }, callback,errback);
}
export function albumPageEventImage(data,callback,errback){
  apiCommon({
    url: '/album_page/event_image',
    method: 'post',
    data
  }, callback,errback);
}
export function removeAlbumImage(data, callback,errback) {
  apiCommon({
    url: '/album_page/remove',
    method: 'post',
    data
  }, callback,errback);
}

export function resetAlbumUse(data,callback,errback){
  apiCommon({
    url: '/album_page/reset_album_use',
    method: 'post',
    data
  }, callback,errback);
}

export function setAlbumUseAll(data,callback,errback){
  apiCommon({
    url: '/album_page/set_album_use_all',
    method: 'post',
    data
  }, callback,errback);
}
export function getRectImages(data,callback,errback){
  apiCommon({
    url: '/album_page/rect_images',
    method: 'post',
    data
  }, callback,errback);
}
export function queryAlbumPageEventImageInfo(data, callback,errback) {
  apiCommon({
    url: '/album_page/rect_image',
    method: 'post',
    data
  }, callback,errback);
}
export function runAlbumPageStatus(data, callback,errback) {
  apiCommon({
    url: '/run_album_page_status',
    method: 'post',
    data
  }, callback,errback);
}
//optimization
export function queryOptimizationParameterInfo(data, callback,errback) {
  apiCommon({
    url: '/optimization_parameter_info',
    method: 'post',
    data
  }, callback,errback);
}

export function editOptimizationParameter(data, callback,errback) {
  apiCommon({
    url: '/edit_optimization_parameter',
    method: 'post',
    data
  },callback,errback);
}

export function startOptimize(data, callback,errback) {
  apiCommon({
    url: '/optimize',
    method: 'post',
    data
  },callback,errback);
}

export function queryOptimizeStatus(data, callback,errback) {
  apiCommon({
    url: '/optimize_status',
    method: 'post',
    data
  },callback,errback);
}

export function queryMatchingStatus(data, callback,errback) {
  apiCommon({
    url: '/matching_status',
    method: 'post',
    data
  },callback,errback);
}


//event
export function createEvent(data, callback,errback) {
  apiCommon({
    url: '/create_event',
    method: 'post',
    data
  },callback,errback);
}

export function editEvent(data, callback,errback) {
  apiCommon({
    url: '/edit_event',
    method: 'post',
    data
  },callback,errback);
}

export function deleteEvent(data, callback,errback) {
  apiCommon({
    url: '/delete_event',
    method: 'post',
    data
  },callback,errback);
}

export function queryEventImagePersons(data, callback,errback) {
  apiCommon({
    url: '/event_image_persons',
    method: 'post',
    data
  },callback,errback);
}

export async function uploadEventImageCheck(data, callback,errback) {
  await apiCommon({
    url: '/upload_event_image_check',
    method: 'post',
    data
  },callback,errback);
}

export function uploadEventImage(data, callback,errback) {
  apiCommon({
    url: '/upload_event_image',
    method: 'post',
    data
  },callback,errback);
}


export function getEventList(data, callback,errback) {
  apiCommon({
    url: '/event_list',
    method: 'post',
    data
  },callback,errback);
}

export function uploadEventImageCheck2(data, callback,errback) {
  apiCommon({
    url: '/upload_event_image_check2',
    method: 'post',
    data
  },callback,errback);
}

//person
export async function uploadPersonCheck(data, callback,errback) {
  await apiCommon({
    url: '/upload_person_check',
    method: 'post',
    data
  },callback,errback);
}

export function uploadPersonImage(data, callback,errback) {
  apiCommon({
    url: '/upload_person',
    method: 'post',
    data
  },callback,errback);
}

export function queryPersonList(data, callback,errback) {
  apiCommon({
    url: '/person_list',
    method: 'post',
    data
  },callback,errback);
}

export function queryUnregisterPersonsList(data, callback,errback) {
  apiCommon({
    url: '/unregister_persons',
    method: 'post',
    data
  },callback,errback);
}

export function queryExtractFaceStatus(data, callback,errback) {
  apiCommon({
    url: '/extract_face_status',
    method: 'post',
    data
  },callback,errback);
}

export function queryExtractEventFaceStatus(data, callback,errback) {
  apiCommon({
    url: '/extract_event_face_status',
    method: 'post',
    data
  },callback,errback);
}


export function comparePersonImage(data, callback,errback) {
  apiCommon({
    url: '/compare_person_image',
    method: 'post',
    data
  },callback,errback);
}

export function registerPersonImage(data, callback,errback) {
  apiCommon({
    url: '/register_person_image',
    method: 'post',
    data
  },callback,errback);
}

export function deleteUnregisterPerson(data, callback,errback) {
  apiCommon({
    url: '/delete_unregister_person',
    method: 'post',
    data
  },callback,errback);
}

export function personImages(data, callback,errback) {
  apiCommon({
    url: '/person_images',
    method: 'post',
    data
  },callback,errback);
}

export function deletePersonImage(data, callback,errback) {
  apiCommon({
    url: '/delete_person_image',
    method: 'post',
    data
  },callback,errback);
}

export function editPerson(data, callback,errback) {
  apiCommon({
    url: '/edit_person',
    method: 'post',
    data
  },callback,errback);
}

//version
export function queryVersionList(data, callback,errback) {
  apiCommon({
    url: '/version_list',
    method: 'post',
    data
  },callback,errback);
}

export function selectVersion(data, callback,errback) {
  apiCommon({
    url: '/select_version',
    method: 'post',
    data
  },callback,errback);
}

export function selectMainVersion(data, callback,errback) {
  apiCommon({
    url: '/select_main_version',
    method: 'post',
    data
  },callback,errback);
}

export function selectShareVersion(data, callback,errback) {
  apiCommon({
    url: '/select_share_version',
    method: 'post',
    data
  },callback,errback);
}

export function createVersion(data, callback,errback) {
  apiCommon({
    url: '/create_version',
    method: 'post',
    data
  },callback,errback);
}

//userユーザー
export function editUser(data, callback,errback) {
  apiCommon({
    url: '/edit_user',
    method: 'post',
    data
  },callback,errback);
}
export function editUserEmail(data, callback,errback) {
  apiCommon({
    url: '/edit_user_email',
    method: 'post',
    data
  },callback,errback);
}

export function editUserPassword(data, callback,errback) {
  apiCommon({
    url: '/edit_user_password',
    method: 'post',
    data
  },callback,errback);
}

//マッチング条件設定
export function startMatching(data, callback,errback) {
  apiCommon({
    url: '/matching',
    method: 'post',
    data
  },callback,errback);
}

export function startAlbumMatching(data, callback,errback) {
  apiCommon({
    url: '/album_matching',
    method: 'post',
    data
  },callback,errback);
}

export function queryWorkList(data, callback,errback) {
  apiCommon({
    url: '/project/work_list',
    method: 'post',
    data
  },callback,errback);
}

export function shareVersion(data, callback,errback) {
  apiCommon({
    url: '/share_version',
    method: 'post',
    data
  },callback,errback);
}

export function copyVersion(data, callback,errback) {
  apiCommon({
    url: '/copy_version',
    method: 'post',
    data
  },callback,errback);
}

export function setAlbumVersion(data, callback,errback) {
  apiCommon({
    url: '/album_version',
    method: 'post',
    data
  },callback,errback);
}

export function uploadPersonStart(data, callback,errback) {
  apiCommon({
    url: '/upload_person_start',
    method: 'post',
    data
  },callback,errback);
}

export function uploadPersonEnd(data, callback,errback) {
  apiCommon({
    url: '/upload_person_end',
    method: 'post',
    data
  },callback,errback);
}

export function uploadEventStart(data, callback,errback) {
  apiCommon({
    url: '/upload_event_start',
    method: 'post',
    data
  },callback,errback);
}

export function uploadEventEnd(data, callback,errback) {
  apiCommon({
    url: '/upload_event_end',
    method: 'post',
    data
  },callback,errback);
}


export function changeSelectAll(data, callback,errback) {
  apiCommon({
    url: '/results/change_select_all',
    method: 'post',
    data
  },callback,errback);
}


export function eventImageEventChange(data, callback,errback) {
  apiCommon({
    url: '/event_image_event_change',
    method: 'post',
    data
  },callback,errback);
}

export function uploadAlbumPageStart(data, callback,errback) {
  apiCommon({
    url: '/upload_album_page_start',
    method: 'post',
    data
  },callback,errback);
}

export function uploadAlbumPageEnd(data, callback,errback) {
  apiCommon({
    url: '/upload_album_page_end',
    method: 'post',
    data
  },callback,errback);
}

export function uploadAlbumPageImage(data, callback,errback) {
  apiCommon({
    url: '/upload_album_page',
    method: 'post',
    data
  },callback,errback);
}

export function unuseAlbumEventImageApi(data, callback,errback) {
  apiCommon({
    url: '/album_page/unuse_images',
    method: 'post',
    data
  },callback,errback);
}

export function getUnRegistEventImages(data, callback,errback) {
  apiCommon({
    url: '/album_page/unregist_images',
    method: 'post',
    data
  },callback,errback);
}

export function eventImageAlbumUseApi(data, callback,errback) {
  apiCommon({
    url: '/album_page/album_use',
    method: 'post',
    data
  },callback,errback);
}

export function eventAlbumUseApi(data, callback,errback) {
  apiCommon({
    url: '/album_page/event_album_use',
    method: 'post',
    data
  },callback,errback);
}

export function reMactchingAlbumEventImageApi(data, callback,errback) {
  apiCommon({
    url: '/album_page/rematching_images',
    method: 'post',
    data
  },callback,errback);
}

export function inquiryMemberApi(data, callback,errback) {
  apiCommon({
    url: '/user/inquiry',
    method: 'post',
    data
  },callback,errback);
}

export function inquiryNoMemberApi(data, callback,errback) {
  apiCommon({
    url: '/inquiry',
    method: 'post',
    data
  },callback,errback);
}

// export function getProjectsStatus(data, callback,errback) {
//   apiCommon({
//     url: '/get_projects_status',
//     method: 'post',
//     data
//   },callback,errback);
// }

export function getChatMessages(data, callback,errback) {
  apiCommon({
    url: '/chat/get_messages',
    method: 'post',
    data
  },callback,errback);
}

export function sendChatMessage(data, callback,errback) {
  apiCommon({
    url: '/chat/send_message',
    method: 'post',
    data
  },callback,errback);
}

export function setMessageRead(data, callback,errback) {
  apiCommon({
    url: '/chat/set_read',
    method: 'post',
    data
  },callback,errback);
}

