import axios from 'axios'
import { ElNotification, ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import { getToken, getRefreshToken, setToken, setRefreshToken, removeToken, removeRefreshToken, removeUserName, removeUser } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from '@/utils/common'
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver'
import useUserStore from '@/store/modules/user';
import { refreshToken } from '@/api/login';
import request from '@/utils/request'

let downloadLoadingInstance;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// インスタンスを生成
const service = axios.create({
  // 
  baseURL: import.meta.env.VITE_APP_BASE_API
})

// request 
service.interceptors.request.use(config => {
  let url = config.url;
  // 重複リクエストをブロックフラグ
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
  if (getToken() && url != "/refresh_token") {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 
  } else {
    config.headers['Authorization'] = 'Bearer ' + getRefreshToken();
  }
  // get リクエストのparams
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put') 
  && config.url!="/upload_person"&& config.url!="/upload_event_image"&& config.url!="/upload_album_page"
 && config.url!="/refresh_token" 
 && config.url!="/extract_face_status"
 && config.url!="/extract_event_face_status"
 && config.url!="/run_album_page_status"
 )
 {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                // URL
      const s_data = sessionObj.data;              // リクエストデータ
      const s_time = sessionObj.time;              // リクエスト時刻
      const interval = 500;                       // 時間間隔(ms)，
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url&&s_url!="/project_info") {
        const message = '同一リクエストを処理しています';
        console.warn(`[${s_url}]: ` + message)
        //console.warn(s_data)
        //console.warn(requestObj.data)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    return Promise.resolve(res.data)
  },
  error => {
    let url = error.response.config.url;
    if (url == "/login") {
      ElMessage({
        icon: null,
        message: error.response.data.message,
        type: 'error',
        duration: 2 * 1000,
        showClose: true
      })
      return Promise.reject(error)
    }else if (url == "/refresh_token") {
      return Promise.reject(error)
    }else if (url == "/logout") {
      let status = error.response.status
      if (status == 401) {
        removeToken();
        removeRefreshToken();
        removeUserName();
        removeUser();
      }
      return Promise.reject(error)
    } else {
      let status = error.response.status
      if(status==401){
        if(url=="/forgot_login_id"||url=="/forgot_password"){
          ElMessage({
            icon: null,
            message: error.response.data.message,
            type: 'error',
            duration: 5 * 1000,
            showClose: true
          })
          return Promise.reject(error)
        }else{
          return Promise.reject(error)
        }
      }else{
        ElMessage({
          icon: null,
          message: error.response.data.message,
          type: 'error',
          duration: 5 * 1000,
          showClose: true
        })
        return Promise.reject(error)
      }
    }
  }
)

// 通用下载方法
export function download(url, params, filename) {
  downloadLoadingInstance = ElLoading.service({ text: "データをダウンロードしています...", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      ElMessage.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    ElMessage.error('ファイルのダウンロードに失敗しました。')
    downloadLoadingInstance.close();
  })
}

export default service
