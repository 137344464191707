<template>
    <el-dialog title="ユーザ情報変更" 
    :model-value="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose" 
    width="480px">
        <div class="info-change-wrap">
            <!--div class="chanage-wrap-row1">
            <div class="left">ログインID</div>
            <div class="right">{{userInfo.login_id}}</div>
            </div-->
            <div class="chanage-wrap-row2">
            <div class="left">メールアドレス</div>
            <div class="right">{{userInfo.email}}</div>
            </div>
            <div class="chanage-wrap-row3">
            <div class="left">
                <div>姓</div>
                <div><el-input v-model="userInfoChangeForm.last_name" class="cus-input2" /></div>
            </div>
            <div class="right">
                <div>名</div>
                <div><el-input v-model="userInfoChangeForm.first_name" class="cus-input2" /></div>
            </div>
            </div>
            <div class="chanage-wrap-row3" style="margin-top: 12px">
            <div class="left">
                <div>セイ</div>
                <div><el-input v-model="userInfoChangeForm.last_name_kana" class="cus-input2" /></div>
            </div>
            <div class="right">
                <div>メイ</div>
                <div><el-input v-model="userInfoChangeForm.first_name_kana"  class="cus-input2"/></div>
            </div>
            </div>
            <div class="change-wrap-row5">
            <div class="left">生年月日</div>
            <div class="right">
                <div class="right-r1">
                <div class="r1-left">
                    <el-select
                    v-model="userInfoChangeForm.year_of_birth"
                    @change="changeYear"
                    placeholder="選ぶ"
                    >
                    <el-option
                        v-for="item in yearOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                    </el-select>
                </div>
                <div class="r1-right">年</div>
                </div>
                <div class="right-r2">
                <div class="r2-left">
                    <el-select
                    v-model="userInfoChangeForm.month_of_birth"
                    @change="changeDays"
                    placeholder="選ぶ"
                    >
                    <el-option
                        v-for="item in monthOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                    </el-select>
                </div>
                <div class="r2-right">月</div>
                </div>
                <div class="right-r3">
                <div class="r3-left">
                    <el-select v-model="userInfoChangeForm.day_of_birth" placeholder="選ぶ">
                    <el-option
                        v-for="item in dayOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                    </el-select>
                </div>
                <div class="r3-right">日</div>
                </div>
            </div>
            </div>
            <div class="change-wrap-row6">
            <div class="r6-btn">
                <div><el-button class="r6-btn-left" @click="handleClose">キャンセル</el-button></div>
                <div><el-button class="r6-btn-right" @click="handleUpdate">変更</el-button></div>
            </div>
            </div>
        </div>
    </el-dialog>
</template>
<script setup>
import { getYearList,getMonthList,getDayList } from '../../utils/common';
import {editUser} from "@/api/projectList";
import { getUser,setUser,setUserName} from '@/utils/auth';
import useUserStore from '@/store/modules/user';
import { ElLoading } from 'element-plus';
const userStore = useUserStore();
const { proxy } = getCurrentInstance();
let userInfo=JSON.parse(getUser());
const dialogFormVisible = ref(false);
defineExpose({getList});
const userInfoChangeForm = ref({
  last_name: '',
  first_name: '',
  last_name_kana: '',
  first_name_kana: '',
  year_of_birth: '',
  month_of_birth: '',
  day_of_birth: ''
})
const yearOptions = ref(getYearList());
const monthOptions = ref(getMonthList());
const dayOptions = ref([]);

let loading=null;
function startLoading() {
  if(loading==null){
    loading = ElLoading.service({
      lock: true,
      text: "loading",
      background: "rgba(0, 0, 0, 0.7)"
    }) 
  }

}

function closeLoading(){
  if(loading){
    loading.close();
    loading=null;
  }
}

function changeYear() {
  userInfoChangeForm.value.day_of_birth = "";
  dayOptions.value = [];
  let year = userInfoChangeForm.value.year_of_birth;
  let month = userInfoChangeForm.value.month_of_birth;
  if (month != null && month != "") {
    dayOptions.value = getDayList(year, month);
  }
}
function changeDays() {
  userInfoChangeForm.value.day_of_birth = "";
  dayOptions.value = [];
  let year = userInfoChangeForm.value.year_of_birth;
  let month = userInfoChangeForm.value.month_of_birth;
  if (year != null && year != "") {
    dayOptions.value = getDayList(year, month);
  }
}


function handleClose(){
  console.log('handleClose---')
  userInfoChangeForm.value={
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    year_of_birth: '',
    month_of_birth: '',
    day_of_birth: ''
  }
  dialogFormVisible.value = false;
}

//update
function handleUpdate(){
  startLoading();
  // alert( JSON.stringify(userInfoChangeForm.value));
  editUser(userInfoChangeForm.value,function(res) {
    userInfo.user_name=userInfoChangeForm.value.last_name+" "+userInfoChangeForm.value.first_name;
    userInfo.last_name=userInfoChangeForm.value.last_name;
    userInfo.first_name=userInfoChangeForm.value.first_name;
    userInfo.last_name_kana=userInfoChangeForm.value.last_name_kana;
    userInfo.first_name_kana=userInfoChangeForm.value.first_name_kana;
    userInfo.date_of_birth=userInfoChangeForm.value.year_of_birth+"-"+userInfoChangeForm.value.month_of_birth+"-"+userInfoChangeForm.value.day_of_birth;
    setUser(JSON.stringify(userInfo));
    setUserName(userInfoChangeForm.value.last_name+" "+userInfoChangeForm.value.first_name);
    userStore.refreshUserName(userInfo.user_name);
    proxy.$modal.msgSuccess(res.message);
    handleClose();
    closeLoading();
  },function(){
    closeLoading();
  });
  
}

function getList(id){
  console.log(userInfo)
  userInfoChangeForm.value.last_name=userInfo.last_name;
  userInfoChangeForm.value.first_name=userInfo.first_name;
  userInfoChangeForm.value.last_name_kana=userInfo.last_name_kana;
  userInfoChangeForm.value.first_name_kana=userInfo.first_name_kana;
  userInfoChangeForm.value.year_of_birth=parseInt(userInfo.date_of_birth.split("-")[0]);
  userInfoChangeForm.value.month_of_birth=parseInt(userInfo.date_of_birth.split("-")[1]);
  userInfoChangeForm.value.day_of_birth=parseInt(userInfo.date_of_birth.split("-")[2]);

  let year = userInfoChangeForm.value.year_of_birth;
  let month = userInfoChangeForm.value.month_of_birth;
  if(year!=null&&year!=""&&month!=null&&month!=""){
    dayOptions.value = getDayList(year, month);
  }
  dialogFormVisible.value=true;
}


// function init(){
//   let year = userInfoChangeForm.value.year_of_birth;
//   let month = userInfoChangeForm.value.month_of_birth;
//   if (year != null && year != "") {
//     dayOptions.value = getDayList(year, month);
//   }
// }
// init();


</script>
<style scoped lang="scss">
@import "@/assets/styles/general.scss";
.info-change-wrap {
  margin: 0 auto;
  width: 440px;
  height: 275px;
  .chanage-wrap-row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 21px;
    .left {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 21px;
      width: 158px;
    }
    .right {
      font-size: 14px;
      color: #1a1a1a;
      line-height: 21px;
    }
  }

  .chanage-wrap-row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 21px;
    margin-top: 7px;
    .left {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 21px;
      width: 158px;
    }
    .right {
      font-size: 14px;
      color: #1a1a1a;
      line-height: 21px;
    }
  }
  .chanage-wrap-row3 {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .left {
      width: 205px;
      height: 100%;
      font-size: 16px;
      color: #1a1a1a;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      :deep(.el-input__inner) {
        width: 170px;
      }
    }
    .right {
      width: 205px;
      height: 100%;
      font-size: 16px;
      color: #1a1a1a;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      :deep(.el-input__inner) {
        width: 170px;
      }
    }
  }
  .change-wrap-row5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-top: 12px;
    .left {
      font-size: 16px;
      color: #1a1a1a;
    }
    .right {
      width: 282px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .right-r1 {
        width: 114px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .r1-left {
          width: 95px;
          height: 100%;
          :deep(.el-input__inner) {
            border-radius: 4px !important;
            border: 1px solid #12678d !important;
          }
        }
        .r1-right {
          height: 21px;
          line-height: 21px;
          color: #1a1a1a;
        }
      }
      .right-r2 {
        width: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .r2-left {
          width: 60px;
          height: 100%;
          :deep(.el-input__inner) {
            border-radius: 4px !important;
            border: 1px solid #12678d !important;
          }
        }
        .r2-right {
          height: 21px;
          line-height: 21px;
          color: #1a1a1a;
        }
      }
      .right-r3 {
        width: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .r3-left {
          width: 60px;
          height: 100%;
          :deep(.el-input__inner) {
            border-radius: 4px !important;
            border: 1px solid #12678d !important;
          }
        }
        .r3-right {
          height: 21px;
          line-height: 21px;
          color: #1a1a1a;
        }
      }
    }
  }
  .change-wrap-row6 {
    margin-top: 32px;
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .r6-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .r6-btn-left {
        border: 2px solid #656565;
        background: #ffffff;
        width: 120px;
        height: 32px;
        font-size: 14px;
        color: #656565;
      }
      .r6-btn-right {
        margin-left: 16px;
        background: #12678d;
        width: 120px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
