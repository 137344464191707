import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token';

const RefreshTokenKey = 'Admin-Refresh-Token';

const UserNameKey = 'Admin-UserName';

const UserKey = 'Admin-User';

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}

export function getUserName() {
  return Cookies.get(UserNameKey)
}

export function setUserName(token) {
  return Cookies.set(UserNameKey, token)
}

export function removeUserName() {
  return Cookies.remove(UserNameKey)
}

export function getUser() {
  return Cookies.get(UserKey)
}

export function setUser(info) {
  return Cookies.set(UserKey, info)
}

export function removeUser() {
  return Cookies.remove(UserKey)
}