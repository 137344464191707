<template>
  <el-scrollbar always  :style="{height:`${contentHeight}px`}">
    <slot></slot>
  </el-scrollbar>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';
const { height } = useWindowSize();
const contentHeight=ref(0);
const value2 = computed(() => props.value2);
const pageMaxHeight = computed(() => props.pageMaxHeight);
const props = defineProps({
  value2: {
    type: String,
    required: true,
  },
  pageMaxHeight: {
    type: Number,
    required: false,
  }
});
onMounted(() => {
  let _height=height.value-value2.value;
  if(pageMaxHeight.value&&pageMaxHeight.value<_height){
    _height=pageMaxHeight.value;
  }
  contentHeight.value=_height;
  window.addEventListener('resize', winResize);
});

function winResize(){
  let _height=height.value-value2.value;
  if(pageMaxHeight.value&&pageMaxHeight.value<_height){
    _height=pageMaxHeight.value;
  }
  contentHeight.value=_height;
}
</script>

<style lang='scss' scoped>
:deep .el-scrollbar__bar.is-vertical {
    width: 10px;
}

:deep .el-scrollbar__bar.is-vertical>div {
    background: #1a1a1a;
}
</style>