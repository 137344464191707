// import router from './router'
// import { ElMessage } from 'element-plus'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// import { getToken } from '@/utils/auth'
// import { isHttp } from '@/utils/validate'
// import useUserStore from '@/store/modules/user'
// import usePermissionStore from '@/store/modules/permission'

// NProgress.configure({ showSpinner: false });

// const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/confirmPassword'];

// const menuList = ['/faceImage/checkUploadFaceImage', '/eventImage/uploadEventImage', '/eventImage/checkEventFaceImage', '/eventImage/activitiesList', '/conditions/conditionsSetting', '/result/resultList', '/result/eventResult', '/result/checkUploadAlbumImage', '/result/personResult', '/result/resultDownload', '/project/projectVersionList'];

// const showPageList = ['/showResult'];

// let flag = 0;

// router.beforeEach((to, from, next) => {
//   NProgress.start()
//   if (showPageList.indexOf(to.path) > -1) {
//     next();
//   } else {
//     if (getToken()) {
//       /* has token*/
//       if (menuList.indexOf(to.path) !== -1) {
//         if (flag == 0) {
//           usePermissionStore().generateRoutes().then(accessRoutes => {
//             accessRoutes.forEach(route => {
//               if (!isHttp(route.path)) {
//                 console.log("route:",route);
//                 router.addRoute(route)
//               }
//             })
//             flag = 1;
//             next({ ...to, replace: true });
//           })
//         } else {
//           next();
//         }
//       }else if(to.path=="/projectList"){
//         next();
//         NProgress.done()
//       }else{
//         next({ path: '/projectList' })
//         NProgress.done()
//       }
//     } else {
//       if (whiteList.indexOf(to.path) !== -1) {
//         next();
//       } else {
//         next("/login");
//         NProgress.done();
//       }
//     }
//   }

// })

// router.afterEach(() => {
//   NProgress.done()
// })

import router from './router'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isHttp } from '@/utils/validate'
import useUserStore from '@/store/modules/user'
import usePermissionStore from '@/store/modules/permission'

NProgress.configure({ showSpinner: false });

const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/reset-password'];

const showPageList = ['/showResult'];

let flag = 0;

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (showPageList.indexOf(to.path) > -1) {
    next();
  } else {
    if (getToken()) {
      /* has token*/
      if (to.path === '/login'||to.path === '/') {
        next({ path: '/projectList' })
        NProgress.done()
      } else {
        if (flag == 0) {
          usePermissionStore().generateRoutes().then(accessRoutes => {
            accessRoutes.forEach(route => {
              if (!isHttp(route.path)) {
                router.addRoute(route)
              }
            })
            flag = 1;
            next({ ...to, replace: true });
          })
        } else {
          next();
        }
      }
    } else {
      // if (whiteList.indexOf(to.path) !== -1) {
      //   next();
      // } else {
      //   next(`/login?redirect=${to.fullPath}`);
      //   NProgress.done();
      // }
      next();
    }
  }

})

router.afterEach(() => {
  NProgress.done()
})
