import cache from './cache'
import modal from './modal'
import download from './download'

export default function installPlugins(app){
  // キャッシュ
  app.config.globalProperties.$cache = cache
  // モーダル
  app.config.globalProperties.$modal = modal
  // ダウンロード
  app.config.globalProperties.$download = download
}
