<template>
  <div>
    <div class="open-view">
      <div v-if="!isCollapse" class="open-icon" @click="toggleSideBar">
        サイドメニューを閉じる
      </div>
      <div v-if="isCollapse" class="close-icon" @click="toggleSideBar"></div>
    </div>
    <el-scrollbar>
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#EFEFEF"
        text-color="#1A1A1A"
        :unique-opened="false"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
          :flag="!isCollapse"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarItem from "./SidebarItem";
import useAppStore from "@/store/modules/app";
import usePermissionStore from "@/store/modules/permission";

const route = useRoute();
const appStore = useAppStore();
const permissionStore = usePermissionStore();

const sidebarRouters = computed(() => permissionStore.sidebarRouters);
const isCollapse = computed(() => !appStore.sidebar.opened);

const activeMenu = computed(() => {
  const { meta, path } = route;
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu;
  }
  return path;
});

function toggleSideBar() {
  appStore.toggleSideBar();
}
</script>
<style lang="scss" scoped>
.open-view {
  width: 100%;
  height: 48px;
  margin: 0 0 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #efefef;
  .open-icon {
    background-image: url("../../../assets/images/openIcon.png");
    background-size: 40px auto;
    background-position: right center;
    background-repeat: no-repeat;
    width: 224px;
    height: 34px;
    margin: 0 8px;
    border: 1px solid #b4b4b4;
    padding-left: 12px;
    /*color: darken(#b4b4b4, 30%);*/
    color: black;
    font-size: 15px;
    line-height: 30px;
    cursor: pointer;
  }
  .close-icon {
    background-image: url("../../../assets/images/closeIcon.png");
    background-size: cover;
    width: 40px;
    height: 26px;
    cursor: pointer;
  }
}
</style>