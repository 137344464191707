<template>
    <el-dialog
      title="登録メールアドレス変更"
      :model-value="regEmailChange"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose" 
      :width="formWidth"
    >
      <div class="email-change-wwrap" v-if="flag==0">
        <div class="email-change-wrap">
          <div class="email-change-wrap-left">
            <!--div class="left-r1">ログインID</div-->
            <div class="left-r2">使用中のメールアドレス</div>
            <div class="left-r3">新メールアドレス</div>
            <div class="left-r4">新メールアドレス確認用</div>
            <div class="left-r5">変更にはパスワード必要です</div>
          </div>
          <div class="email-change-wrap-right">
            <!--div class="right-r1">{{userInfo.login_id}}</div-->
            <div class="right-r2">{{userInfo.email}}</div>
            <div class="right-r3">
              <el-input
                v-model="emailInfoChanageForm.email"
                class="cus-input2"
              />
            </div>
            <div class="right-r4">
              <el-input
                v-model="emailInfoChanageForm.email_confirmation"
                class="cus-input2"
              />
            </div>
            <div class="right-r5">
              <el-input
                v-model="emailInfoChanageForm.password"
                class="cus-input2"
                type="password"
                autocomplete="new-password"
                v-if="closeEyes"
              />
              <el-input
                v-model="emailInfoChanageForm.password"
                class="cus-input2"
                type="text"
                autocomplete="new-password"
                v-if="showEyes"
              />
            </div>
            <div class="right-r6">
              <div>
                <img
                  src="@/assets/icons/svg/eyes.svg"
                  class="eyesStyle"
                  @click="changeInput"
                  v-if="showEyes"
                />
                <img
                  src="@/assets/icons/svg/eyes2.svg"
                  class="eyesStyle"
                  @click="changeInput"
                  v-if="closeEyes"
                />
              </div>
              <div style="font-size: 12px">パスワードを表示する</div>
            </div>
          </div>
        </div>
        <div class="email-change-bottom">
          <div class="email-change-bottom-wrap">
            <div>
              <el-button class="email-change-btn-left" @click="handleClose">キャンセル</el-button>
            </div>
            <div>
              <el-button class="email-change-btn-right" @click="handleUpdate">変更</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="authentication" v-if="flag==1">
        <div class="content">
          <div class="wrap">
            <div class="authentication__headline">
              以下のメールアドレスに認証コードが送信されました。
            </div>
            <div class="bordered">
              <div class="bordered__inner">
                <span class="bordered__txt">{{ newEmail }}</span>
              </div>
            </div>
            <div class="clmn1">
              <p class="text-center">
                メールの受信箱を確認し、認証コード入力欄に認証コードを入力してください。
              </p>
              <p class="font-bold text-center">
                コードの有効時間まで、あと<span class="timer">{{
                  formatTimer(time)
                }}</span>
              </p>
            </div>
            <div class="clmn1 mb-4">
              <p class="text-center">認証コード入力欄</p>
              <div class="cus-input2 mb-5">
                <input
                  class="el-input__inner text-center"
                  type="text"
                  autocomplete="off"
                  v-model="verify_code"
                />
              </div>
              <button class="button" @click="verifyEvent">認証</button>
            </div>
            <div class="dialog">
              <div class="dialog__inner" v-if="errorVerifyMsg!=''" >
                <div class="dialog__ttl text-center font-bold">
                  認証エラー
                </div>
                <div class="dialog__txt text-center">
                  {{errorVerifyMsg}}
                </div>
                <button class="subbutton" @click="sendCodeEvent">コードを再送信</button>
              </div>
            </div>
            <div class="clmn2">
              <button class="clmn2__button subbutton" @click="goLogin">登録画面に戻る</button>
              <button class="clmn2__button subbutton" @click="sendCodeEvent">コードを再送信</button>
            </div>
            <div class="clmn1 text-center">
              <a class="txtlink" href="#"
                >コードが届かない。確認できない場合</a
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
</template>
<script setup>
import {editUserEmail} from "@/api/projectList";
import { verifyChangeMail,sendCode } from "@/api/login";
import { getUser} from '@/utils/auth';
import { ElLoading } from 'element-plus';
import useUserStore from "@/store/modules/user";
const userStore = useUserStore();
const { proxy } = getCurrentInstance();
const userInfo=JSON.parse(getUser());
const regEmailChange = ref(false);
defineExpose({getList});
const emailInfoChanageForm = ref({
  email: "",
  email_confirmation: "",
  password: "",
});
const showEyes = ref(false);
const closeEyes = ref(true);

let flag=ref(0);
let newEmail = ref("");
let verify_code = ref("");
let time = ref("600");
let formWidth=ref("480px")

let loading=null;
function startLoading() {
  if(loading==null){
    loading = ElLoading.service({
      lock: true,
      text: "loading",
      background: "rgba(0, 0, 0, 0.7)"
    }) 
  }

}

function closeLoading(){
  if(loading){
    loading.close();
    loading=null;
  }
}

function changeInput() {
  if (showEyes.value) {
    showEyes.value = false;
    closeEyes.value = true;
  } else {
    showEyes.value = true;
    closeEyes.value = false;
  }
}

function handleClose() {
    emailInfoChanageForm.value={
      email: "",
      email_confirmation: "",
      password: "",
    }
    regEmailChange.value = false;
    formWidth.value="480px";
    flag.value=0;
    newEmail.value = "";
    verify_code.value="";
    time.value=600;
    errorVerifyMsg.value="";
}

function handleAuth() {
    formWidth.value="600px";
    flag.value=1;
    newEmail.value = emailInfoChanageForm.value.email;
    verify_code.value="";
    errorVerifyMsg.value="";
    setTimer();
    emailInfoChanageForm.value={
      email: "",
      email_confirmation: "",
      password: "",
    }
}

function setTimer() {
  let timer = setInterval(() => {
    time.value--;
    if (time.value <= 0) {
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
}

function formatTimer(t) {
  let s = t % 60;
  let res = Math.floor(t / 60) + ":" + (s.toString().length == 2 ? s : "0" + s);
  return res;
}

let errorVerifyMsg=ref("");

function verifyEvent() {
  startLoading();
  verifyChangeMail({
    "id": userInfo.id,
    "verify_code": verify_code.value,
  }).then((res) => {
    closeLoading();
    errorVerifyMsg.value="";
    proxy.$modal.msgSuccess(res.message);
    setTimeout(() => {
      userStore
        .logOut()
        .then(() => {
          location.href = "/login";
        })
        .catch(() => {
          location.href = "/login";
        });
    }, 2000);
  }).catch((error)=>{
    errorVerifyMsg.value=error.response.data.message;
    proxy.$modal.msgError(error.response.data.message);
    closeLoading();
  });
}

function sendCodeEvent(){
  startLoading();
  sendCode({
    "id": userInfo.id,
    "code_use": 2,
  }).then((res) => {
    proxy.$modal.msgSuccess(res.message);
    time.value=600;
    setTimer();
    closeLoading();
  }).catch((error)=>{
    proxy.$modal.msgError(error.response.data.message);
    closeLoading();
  });
}

function goLogin(){
  userStore
        .logOut()
        .then(() => {
          location.href = "/login";
        })
        .catch(() => {
          location.href = "/login";
        });
}

//update
function handleUpdate(){
  startLoading();
  editUserEmail(emailInfoChanageForm.value,function(res){
    proxy.$modal.msgSuccess(res.message);
    handleAuth();
    closeLoading();
  },function(){
    closeLoading();
  });
}
function getList(id) {
  regEmailChange.value = true;
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/index.css";
@import "@/assets/styles/authentication.css";
.authentication .wrap{
  padding: 0 !important;
}
.authentication .content{
  margin: 0 !important;
}
.authentication{
  background-color: #FFFFFF !important;
  color: #1a1a1a !important;
  height: 700px !important;
  min-height: 700px !important;
}
.authentication .bordered__inner{
  height: 52px !important;
}
.email-change-wwrap {
  width: 440px;
  height: 279px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .email-change-wrap {
    width: 100%;
    height: 215px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .email-change-wrap-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 194px;
      .left-r1 {
        width: 100%;
        height: 21px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 21px;
      }
      .left-r2 {
        margin-top: 3px;
        width: 100%;
        height: 21px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 21px;
      }
      .left-r3 {
        margin-top: 6px;
        width: 100%;
        height: 40px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 40px;
      }
      .left-r4 {
        margin-top: 8px;
        width: 100%;
        height: 40px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 40px;
      }
      .left-r5 {
        margin-top: 5px;
        width: 117px;
        height: 45px;
        font-size: 16px;
        color: #1a1a1a;
        // line-height: 45px;
      }
    }
    .email-change-wrap-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 245px;
      height: 100%;
      .right-r1 {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 21px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 21px;
      }
      .right-r2 {
        margin-top: 3px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 21px;
        font-size: 16px;
        color: #1a1a1a;
        line-height: 21px;
      }
      .right-r3 {
        margin-top: 6px;
        width: 100%;
        height: 40px;
        :deep(.el-input__inner) {
          height: 40px;
        }
      }
      .right-r4 {
        margin-top: 8px;
        width: 100%;
        height: 40px;
        :deep(.el-input__inner) {
          height: 40px;
        }
      }
      .right-r5 {
        margin-top: 8px;
        width: 100%;
        height: 40px;
        :deep(.el-input__inner) {
          height: 40px;
        }
      }
      .right-r6 {
        margin-top: 4px;
        margin-right: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .eyesStyle {
          vertical-align: middle;
          margin-top: 0;
          width: 24px;
          height: 24px;
        }
        .eyesStyle:hover {
          cursor: pointer;
        }
      }
    }
  }
  .email-change-bottom {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-top: 32px;
    .email-change-bottom-wrap {
      width: 256px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .email-change-btn-left {
        width: 120px;
        height: 30px;
        border: 2px solid #656565;
        background: #ffffff;
        font-size: 14px;
        color: #656565;
      }
      .email-change-btn-right {
        width: 120px;
        height: 100%;
        border: 1px solid #ffffff;
        background: #12678d;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
