import Cookies from 'js-cookie';
import cache from "@/plugins/cache";
import {
  getProjectInfo
} from "@/api/projectList";

const useAppStore = defineStore(
  'app',
  {
    state: () => ({
      sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false,
        hide: false
      },
      size: 'default',
      projectInfo:{},
      bottomInfo:{"project_name":"","version_id":""}
    }),
    actions: {
      toggleSideBar(withoutAnimation) {
        if (this.sidebar.hide) {
          return false;
        }
        this.sidebar.opened = !this.sidebar.opened
        this.sidebar.withoutAnimation = withoutAnimation
        if (this.sidebar.opened) {
          Cookies.set('sidebarStatus', 1)
        } else {
          Cookies.set('sidebarStatus', 0)
        }
      },
      closeSideBar(withoutAnimation) {
        if (this.sidebar.opened) {
          Cookies.set('sidebarOldSatus', 1);
        }
        else {
          Cookies.set('sidebarOldSatus', 0)
        }
        
        Cookies.set('sidebarStatus', 0)
        this.sidebar.opened = false
        this.sidebar.withoutAnimation = withoutAnimation
      },
      restoreSideBar(withoutAnimation) {
        var oldStatus = Cookies.get('sidebarOldSatus');
        if (oldStatus == 1)
        {
          Cookies.set('sidebarStatus', 1)
          this.sidebar.opened = true
          this.sidebar.withoutAnimation = withoutAnimation
        }

      },
      toggleSideBarHide(status) {
        this.sidebar.hide = status
      },
      refreshProjectInfo(){
        console.log('app store refreshProjectInfo');
        let projectId=cache.local.get("projectId");
        let params = {};
        params.project_id = projectId;
        params.work_pc = "test";
        let that=this;
        getProjectInfo(params,function(res){
          console.log(res);
          that.projectInfo=res;
          that.bottomInfo.project_name=res.project_name;
          that.bottomInfo.version_name=res.version_name;
          that.bottomInfo.age_group=res.age_group;
          that.bottomInfo.face_threshold=res.face_threshold;
          that.bottomInfo.album_face_threshold = res.album_face_threshold;
          that.bottomInfo.album_face_size = res.album_face_size;
          that.bottomInfo.album_profile_degree = res.album_profile_degree;
          let isSub = cache.local.get("isSub");
          let sharedFlag = cache.local.get("sharedFlag");
          let workSub = cache.local.get("workSub");
          let subNum = cache.local.get("subNum");
          if(isSub!=res.is_sub
            ||sharedFlag!=res.shared_flag 
            || workSub!=res.work_sub 
            || subNum != res.subuser_num){
            cache.local.set("isSub", res.is_sub);
            cache.local.set("sharedFlag", res.shared_flag);
            cache.local.set("workSub", res.work_sub);
            cache.local.set("subNum",res.subuser_num);
            window.location.href=window.location.href;
          }
        });
        
      },
      getBottomInfo(projectName,versionName,age_group,face_threshold){
        this.bottomInfo.project_name=projectName;
        this.bottomInfo.version_name=versionName;
        this.bottomInfo.age_group=age_group;
        this.bottomInfo.face_threshold=face_threshold;
      }
    }
  })

export default useAppStore
