<template>
  <div :class="classObj" class="app-wrapper">
    <div class="header1">
      <navbar
        @show-user-info-change="showUserInfoChange"
        @show-email-change="showEmailChange"
        @show-pwd-change="showPwdChange"
        @show-usage="showUsage"
        @show-terms-of-use="showTermsOfUse"
        @show-policy="showPolicy"
        @show-inquiry="showInquiry"
        @with-drawal-event="openWithdrawalDialog"
        :isSub="isSub"
        :workSub="workSub"
        :master_flag="master_flag"
        :isViewer="isViewer"
      />
    </div>
    <div class="header2">
      <div class="project-detail-m">
        <!-- <div class="left">
          <div class="logo"></div>
          <div style="margin-left: 5px">
            {{ projectInfo.project_name }}（{{ projectInfo.version_name }}）
          </div>
        </div> -->
        <div class="right">
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            登録イベント数：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{ projectInfo.event_num }}
          </div>
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            顔検索対象人数：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{ projectInfo.person_num }}
          </div>
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            選択画像枚数：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{ projectInfo.selected_image_num }}
          </div>
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            イベント登録画像枚数合計：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{ projectInfo.event_image_num }}
          </div>
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            アルバム画像枚数：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{ projectInfo.albumpage_num }}
          </div>
          <div style="font-size: 16px; margin-right: 5px; color: #ffffff">
            累積アップロード画像枚数：
          </div>
          <div style="font-size: 16px; margin-right: 20px; color: #ffffff">
            {{
              projectInfo.person_upload_num + projectInfo.event_image_upload_num + projectInfo.albumpage_num_total
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <sidebar class="sidebar-container" />
      <div class="main-container">
        <div class="header-info" v-if="0">
          <p>ダミーメッセージ、プロジェクト名：○○○○、Subユーザ1がメッセージを送信しました</p>
        </div>
        <app-main />
      </div>
    </div>
    <!-- 
    <el-dialog title="パスワード変更" v-model="pwdChange" width="480px">
      <div class="pwd-change-wrap">
        <div class="pwd-change-r1">
          <div class="pwd-change-r1-left">ログインID</div>
          <div class="pwd-change-r1-right">kdjcdldk</div>
        </div>
        <div class="pwd-change-r1" style="margin-top: 3px">
          <div class="pwd-change-r1-left">メールアドレス</div>
          <div class="pwd-change-r1-right">aaaaaaa@dddddd.co.jp</div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 6px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">現在のパスワード</div>
            <div class="pwd-change-r2-top-right">
              <el-input v-model="pwdChangeForm.password" type="password" class="cus-input2"/>
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeEyesShow1"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 8px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">新パスワード</div>
            <div class="pwd-change-r2-top-right">
              <el-input v-model="pwdChangeForm.newPassword" type="password" class="cus-input2" />
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeEyesShow1"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 8px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">現在のパスワード</div>
            <div class="pwd-change-r2-top-right">
              <el-input
                v-model="pwdChangeForm.confrimNewPassword"
                type="password"
                class="cus-input2"
              />
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeEyesShow1"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r3-bottom">
          <div class="pwd-change-r3-btn">
            <div><el-button class="left-btn">キャンセル</el-button></div>
            <div><el-button class="right-btn">変更</el-button></div>
          </div>
        </div>
      </div>
    </el-dialog> -->
    <change-user-info ref="changeUserInfoRef" />
    <change-email ref="changeEmailRef" />
    <change-password ref="changePasswordRef" />

    <el-dialog
      v-model="termsOfUseDialogVisible"
      title="ご利用規約"
      width="784px"
      center
    >
      <scroll-view value2="300">
        <terms-of-use></terms-of-use>
      </scroll-view>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="termsOfUseDialogVisible = false">閉じる</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="policyDialogVisible"
      title="プライバシーポリシー"
      width="784px"
      center
    >
      <scroll-view value2="300">
        <policy></policy>
      </scroll-view>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="policyDialogVisible = false">閉じる</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="usageDialogVisible"
      title="ご利用方法"
      width="784px"
      center
    >
      <scroll-view value2="300">
        <usage></usage>
      </scroll-view>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="usageDialogVisible = false">閉じる</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="inquiryVisible"
      title="お問合せ"
      width="820px"
      center
    >
      <scroll-view value2="300">
        <inquiry @close-event="inquiryVisible = false"></inquiry>
      </scroll-view>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="inquiryVisible = false">閉じる</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="退会の確認"
      v-model="withdrawalDialogVisible"
      width="480px"
    >
      <div class="dialog-gp-wrap">
        <div class="gp-top" v-if="pay_type==0">退会をすると、全てのプロジェクトが削除されます。課金され、画像保存期間中の画像も削除されます。また、再度同じメールアドレスでユーザ登録がされても、現在使用中のプロジェクトは復活しませんので、ご注意ください。</div>
        <div class="gp-top" v-if="pay_type==1">お客様はカード決済のご契約ではないため、ここからは、退会の処理ができません。<br/>
        ご契約内容とお支払い状況の確認が必要となりますので、誠に
        お手数をお掛けいたしますが、<br/>
        「お問い合わせ」<br/>
        から、退会ご希望の旨と、ご登録の連絡先を入力し、ご連絡
        ください。<br/>
        折り返し、登録メールアドレス宛にご連絡を差し上げます。
        </div>
        <div v-if="pay_type==0" class="gp-middle -notice flexbox">
          <input class="el-checkbox__type1 flex-shrink-0 mr-3" type="checkbox" v-model="withdrawalDialogDisabled" >
          <div class="txt">左のチェックボックスにチェックをすると、削除ボタンが押せるようになります。<br /><div style="font-weight:bold;">退会をすると、現在使用中の全てプロジェクトが削除されます。画像保存期間が終了してからの退会をお勧めします。</div></div>
        </div>
        <div class="gp-bottom" style="margin-top: 32px">
          <div>
            <el-button class="gp-bottom-btn1" @click="cancelWithdrawalDialog"
              >キャンセル</el-button
            >
          </div>
          <div v-if="pay_type==0">
            <el-button v-if="withdrawalDialogDisabled" class="gp-bottom-btn2" @click="withdrawal"
              >削除する</el-button
            >
            <el-button v-if="!withdrawalDialogDisabled" class="gp-bottom-btn2" disabled
              >削除する</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import originElementPlus from "element-plus/theme-chalk/index.css";
import Sidebar from "./components/Sidebar/index.vue";
import AppMain from "./components/AppMain.vue";
import Navbar from "./components/Navbar.vue";
import ChangeUserInfo from "../components/ChangeUserInfo/index.vue";
import ChangeEmail from "../components/ChangeEmail/index.vue";
import ChangePassword from "../components/ChangePassword/index.vue";
import ScrollView from "@/components/ScrollView/index.vue";
import TermsOfUse from "@/layout/components/termsOfUse";
import policy from "@/layout/components/policy";
import usage from "@/layout/components/usage";
import inquiry from "@/layout/components/inquiry";
import { getYearList, getMonthList, getDayList } from "../utils/common";
import useAppStore from "@/store/modules/app";
import { getUser,getToken} from '@/utils/auth';
import cache from "@/plugins/cache";
import useUserStore from "@/store/modules/user";
import { ElLoading, ElMessageBox } from "element-plus";
import {
  withdrawalEvent,
} from "@/api/projectList";

import Pusher from 'pusher-js';

const userStore = useUserStore();

const { proxy } = getCurrentInstance();

const baseUrl = import.meta.env.VITE_APP_WSS_API;
const pusherAppKey = import.meta.env.VITE_PUSHER_APP_KEY;

const pusher = new Pusher(pusherAppKey, {
  cluster: 'ap3',
  authEndpoint: baseUrl+'/broadcasting/auth',
  auth: {
      headers: {
        "Authorization": "Bearer "+ getToken(),
        "Access-Control-Allow-Origin": "*"
      }
    }
});
let user_id=JSON.parse(getUser()).id;

const appStore = useAppStore();
const changeUserInfoRef = ref();
const changeEmailRef = ref();
const changePasswordRef = ref();
const sidebar = computed(() => useAppStore().sidebar);
const classObj = computed(() => ({
  hideSidebar: !sidebar.value.opened,
  openSidebar: sidebar.value.opened,
  withoutAnimation: sidebar.value.withoutAnimation,
}));

let master_flag=JSON.parse(getUser()).master_flag;
let pay_type=JSON.parse(getUser()).pay_type;

const projectInfo = computed(() => useAppStore().projectInfo);

const yearOptions = ref(getYearList());
const monthOptions = ref(getMonthList());
const dayOptions = ref([]);

const regEventCount = ref("12件");
const faceImageCount = ref("320人");
const selectImageCount = ref("1200枚");
const eventImageCount = ref("8300枚");
const uploadImageCount = ref("12000枚");

let isSub = cache.local.get("isSub");
let workSub = cache.local.get("workSub");
let isViewer = cache.local.get("isViewer");

let withdrawalDialogVisible=ref(false);
let withdrawalDialogDisabled=ref(false);

const userInfoChange = ref(false);
const userInfoChangeForm = ref({
  surname: "",
  name: "",
  surname2: "",
  name2: "",
  year: "2000",
  month: "1",
  day: "1",
});

let termsOfUseDialogVisible = ref(false);
let policyDialogVisible = ref(false);
let usageDialogVisible = ref(false);
let inquiryVisible = ref(false);

const pwdChange = ref(false);
const pwdChangeForm = ref({
  password: "",
  newPassword: "",
  confrimNewPassword: "",
});

function showUserInfoChange() {
  // userInfoChange.value = true;
  nextTick(() => {
    changeUserInfoRef.value.getList(1);
  });
}

function showEmailChange() {
  nextTick(() => {
    changeEmailRef.value.getList(1);
  });
}
function showPwdChange() {
  nextTick(() => {
    changePasswordRef.value.getList(1);
  });
}

function showUsage() {
  usageDialogVisible.value = true;
}

function showTermsOfUse() {
  termsOfUseDialogVisible.value = true;
}

function showPolicy() {
  policyDialogVisible.value = true;
}

function showInquiry() {
  inquiryVisible.value = true;
}

onMounted(() => {
  appStore.refreshProjectInfo();
  let project_id=cache.local.get("projectId");
  const channel = pusher.subscribe('private-sa_event.'+user_id);
  channel.bind('sa-event', (data) => {
    console.log("event:",data);
    if(data.message.category==3&&data.message.project_id==project_id){
      if(data.message.action_type==1){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==2){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==3){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
    }
    if(data.message.category==5&&data.message.project_id==project_id){
      if(data.message.action_type==1){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==2){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==3){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==4){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==5){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==6){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
      }
      if(data.message.action_type==7){
        proxy.$modal.msgSuccess2(data.message.message,data.message.show_time);
        userStore.logOut().then(() => {
          location.href = "/login";
        }).catch(() => {
          location.href = "/login";
        });
      }
    }
  });
});

function openWithdrawalDialog() {
  withdrawalDialogVisible.value = true;
  withdrawalDialogDisabled.value=false;
}

function cancelWithdrawalDialog() {
  withdrawalDialogVisible.value = false;
}

function withdrawal(){
  startLoading();
  let params = {};
  withdrawalEvent(
    params,
    function (res) {
      proxy.$modal.msgSuccess(res.message);
      setTimeout(() => {
        window.location.href=window.location.href;
      }, 2000);
      closeLoading();
    },
    function () {
      closeLoading();
    }
  );
}

let loading = null;
function startLoading() {
  if (loading == null) {
    loading = ElLoading.service({
      lock: true,
      text: "loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  }
}

function closeLoading() {
  if (loading) {
    loading.close();
    loading = null;
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/index.css";
.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .header1 {
    min-width: 1366px;
    width: 100%;
    height: 60px;
    //position: fixed;
    // z-index: 2000;
  }
  .header2 {
    min-width: 1366px;
    width: 100%;
    height: 40px;
    //position: fixed;
    // z-index: 2000;
    //margin-top: 60px;
    .project-detail-m {
      background-color: #12678d;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        color: #ffffff;
        .logo {
          background-image: url("@/assets/icons/svg/svg7.svg");
          background-size: cover;
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 12px;
      }
    }
  }
  // .content {
  //   .sidebar-container {
  //     padding-top: 100px;
  //   }
  // }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 200px);
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.sidebarHide .fixed-header {
  width: 100%;
}

.pwd-change-wrap {
  width: 440px;
  height: 347px;
  margin: 0 auto;
  .pwd-change-r1 {
    width: 100%;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .pwd-change-r1-left {
      width: 158px;
      font-size: 16px;
      color: #1a1a1a;
      line-height: 21px;
    }
    .pwd-change-r1-right {
      font-size: 14px;
      line-height: 21px;
      color: #1a1a1a;
    }
  }
  .pwd-change-r2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 72px;
    .pwd-change-r2-top {
      height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .pwd-change-r2-top-left {
        width: 158px;
      }
      .pwd-change-r2-top-right {
        width: 282px;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .pwd-change-r2-bottom {
      margin-top: 4px;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-right: 24px;
      .eyesStyle {
        vertical-align: middle;
        margin-top: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
  .pwd-change-r3-bottom {
    margin-top: 32px;
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .pwd-change-r3-btn {
      width: 256px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left-btn {
        width: 120px;
        height: 30px;
        border: 2px solid #656565;
        font-size: 14px;
        color: #656565;
        background: #ffffff;
      }
      .right-btn {
        width: 120px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
        background: #12678d;
      }
    }
  }
}
</style>