import axios from 'axios'
import { ElNotification, ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from '@/utils/common'
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver'
import useUserStore from '@/store/modules/user';
import { refreshToken } from '@/api/login';
import request2 from '@/utils/request2'

let downloadLoadingInstance;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// インスタンスを生成
const service = axios.create({
  // 
  baseURL: import.meta.env.VITE_APP_BASE_API,
  // タイムアウト
  timeout: 10000
})

// request
service.interceptors.request.use(config => {
  console.log("22:", config.headers);
  let url = config.url;
  // 重複リクエストをブロックフラグ
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
  if(config.token){
    config.headers['Authorization'] = 'Bearer ' + config.token
  }
  // get リクエストのparams
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                // URL
      const s_data = sessionObj.data;              // リクエストデータ
      const s_time = sessionObj.time;              // リクエスト時刻
      const interval = 1000;                       // 時間間隔(ms)，
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url && s_url != "/project_info") {
        const message = '同一リクエストを処理しています';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  return Promise.resolve(res.data)
},
  error => {
    let url = error.response.config.url;
    let status = error.response.status
    if (status == 200) {
      //success
    } else {
      ElMessage({
        icon: null,
        message: error.response.data.message,
        type: 'error',
        duration: 5 * 1000,
        showClose: true
      })
    }
    return Promise.reject(error)
  }
)

export default service
