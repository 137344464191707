import { ElMessage, ElMessageBox, ElNotification, ElLoading } from 'element-plus'

let loadingInstance;

export default {
  // メッセージ表示
  msg(content) {
    ElMessage.info(content)
  },
  // エラーメッセージ
  msgError(content) {
    if(content!=""){
      ElMessage.error(content)
    }
  },
  // 成功メッセージ
  msgSuccess(content) {
    if(content!=""){
      ElMessage.success(content)
    }
  },


  // 成功メッセージ２
  msgSuccess2(content,time) {
    if(content!=""){
      if(time!=null&&time!=undefined){
        ElMessage({
          icon: null,
          message: content,
          type: 'success',
          duration: time * 1000,
          showClose: false
        })
      }else{
        ElMessage.success(content)
      }
    }
  },

  // ワーニング
  msgWarning(content) {
    ElMessage.warning(content)
  },
  // アラート
  alert(content) {
    ElMessageBox.alert(content, "アラート")
  },
  // エラーメッセージ
  alertError(content) {
    ElMessageBox.alert(content, "エラー", { type: 'error' })
  },
  // メッセージ
  alertSuccess(content) {
    ElMessageBox.alert(content, "メッセージ", { type: 'success' })
  },
  // ワーニング
  alertWarning(content) {
    ElMessageBox.alert(content, "警告", { type: 'warning' })
  },
  // 通知
  notify(content) {
    ElNotification.info(content)
  },
  // エラー通知
  notifyError(content) {
    ElNotification.error(content);
  },
  // 成功通知
  notifySuccess(content) {
    ElNotification.success(content)
  },
  // ワーニング通知
  notifyWarning(content) {
    ElNotification.warning(content)
  },
  // 確認ダイアログ
  confirm(content) {
    return ElMessageBox.confirm(content, "確認", {
      confirmButtonText: 'OK',
      cancelButtonText: 'キャンセル',
      type: "warning",
    })
  },
  // プロンプト
  prompt(content) {
    return ElMessageBox.prompt(content, "確認", {
      confirmButtonText: 'OK',
      cancelButtonText: 'キャンセル',
      type: "warning",
    })
  },
  // ローディング
  loading(content) {
    loadingInstance = ElLoading.service({
      lock: true,
      text: content,
      background: "rgba(0, 0, 0, 0.7)",
    })
  },
  // ロード終了
  closeLoading() {
    loadingInstance.close();
  },

  // ヘルプを開く
  openHelp(url) {
    window.open(url, 'superalbum_help', "width=728, height=1200,top=0, left=200,resizable=yes, scrollbars=yes");
  },

  // アルバム画像の拡大を開く
  openLoupe(url) {
    window.open(url, '_blank', "width=1366, height=1200,top=0, left=100,resizable=yes, scrollbars=yes");
  }
}
