<template>
<div class="inquiry">
    <div class="inquiry__wrap">
      <div class="inquiry__container">
        <div class="inquiry__ttlwrap" v-if="!isConfirm">
          <h1 class="inquiry__pagettl">お問合せ</h1>
          <p class="inquiry__lead">必要な情報を入力してください</p>
        </div>
        <div class="inquiry__ttlwrap" v-if="isConfirm">
          <h1 class="inquiry__pagettl">お問合せ内容の確認</h1>
          <p class="inquiry__lead">入力内容を確認してください</p>
        </div>
        <section class="formContent">
          <form action="" method="post">
            <div class="inquiry__item">
              <div class="inquiry__label"></div>
              {{ userName }} 様
              <div class="inquiry__label"></div>
              ご登録メールアドレス： {{ userInfo.email }}          
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">
                <label for="projectName">ご利用中のプロジェクト名:</label>
              </div>
              <div class="inquiry__input">
                <input id="projectName" type="text" name="projectName" v-model="form.project_name" readonly />
              </div>
              <div class="errorMsg" id="projectNameError"></div>
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">
                <label for="viewName">画面名:</label>
              </div>
              <div class="inquiry__input">
                <div class="selectWrap" v-if="!isConfirm" >
                  <select class="formItem" id="viewName" name="viewName" v-model="form.view_id" @change="changeView">
                      <option :key="item.id" v-for="item in viewList" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>
                <input v-if="isConfirm" id="viewName" type="text" name="viewName" v-model="form.view_name" readonly />
                <div class="errorMsg" id="viewNameError" v-if="showViewNameError">※お問い合わせの画面を選択してください</div>
              </div>
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">
                <label for="contactType">ご連絡の内容を選択してください:</label>
              </div>
              <div class="inquiry__input" >
                <div class="selectWrap" v-if="!isConfirm">
                  <select class="formItem" id="contactType" name="contactType" v-model="form.contact_type" @change="changeCantactType">
                      <option :key="item.id" v-for="item in contactTypeList" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>
                <input v-if="isConfirm" id="contactType" type="text" name="contactType" v-model="form.contact_type_name" readonly />
                <div class="errorMsg" id="contactTypeError" v-if="showContactTypeError">※ご連絡の内容を選択してください</div>
                <div id="otherField" v-if="form.contact_type==5">
                  <label class="otherInput" for="otherInput">その他を選択した方は内容を入力してください：</label>
                  <input type="text" id="otherInput" name="otherInput" v-model="form.contact_type_other" :readonly="isConfirm"/>
                </div>
                <div class="errorMsg" id="otherInputError" v-if="showContactOtherError">※その他の内容を入力してください</div>
              </div>
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">
                <label for="contactMethod">弊社からのご連絡方法を選択してください:</label>
              </div>
              <div class="inquiry__input">
                <div class="selectWrap" v-if="!isConfirm">
                  <select class="formItem" id="contactMethod" name="contactMethod" v-model="form.contact_method" @change="changeCantactMethod">
                      <option :key="item.id" v-for="item in contactMethodList" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>
                <input v-if="isConfirm" id="contactMethod" type="text" name="contactMethod" v-model="form.contact_method_name" readonly />
                <div class="errorMsg" id="contactMethodError" v-if="showContactMethodError">※弊社からのご連絡方法を選択してください</div>
              </div>
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">
                <label for="details">ご連絡内容の詳細:<span class="small block font-normal" v-if="!isConfirm">ZOOMや電話での対応ご希望の場合は、ご希望日時を第3候補まで入れてください。<br>尚、対応時間は平日の10～12時、13～16時となりますので、ご了承ください。</span></label>
              </div>
              <div class="inquiry__input">
                <el-input type="textarea" id="details" name="details" rows="4" v-model="form.details" v-if="!isConfirm"/>
                <p v-if="isConfirm">{{ form.details }}</p>
              </div>
              <div class="errorMsg" id="detailsError" v-if="showDetailsError">※ご連絡内容の詳細を入力してください</div>
            </div>
            <div class="inquiry__item">
              <div class="inquiry__label">添付ファイルがある場合は「ファイル選択」をクリックして、ファイルを添付してください。</div>
              <div class="inquiry__input">
                <input type="file" id="inquiry_file" @change="changeFile" style="display: none">
                <el-button class="-reset" @click="selectedFile" v-if="!isConfirm">ファイルを選択</el-button>
                <p>{{ form.file_name }}</p>
              </div>
            </div>
            <div class="inquiry__item -submit" v-if="!isConfirm">
              <el-button class="el-button -reset" @click="clearForm">クリア</el-button>
              <el-button class="el-button el-button--default" @click="confirmSubmission">送信内容を確認する</el-button>
            </div>
            <div class="inquiry__item -submit" v-if="isConfirm">
              <el-button class="el-button -reset" @click="returnForm">前の画面に戻る</el-button>
              <el-button class="el-button el-button--default" @click="sendForm">送信する</el-button>
            </div>
          </form>
        </section>
      </div>
    </div>

</div>
</template>

<script setup>
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import { computed, proxyRefs } from "vue";
import {
  inquiryMemberApi
} from "@/api/projectList";
import { ElLoading } from 'element-plus';
import cache from "@/plugins/cache";
const router = useRouter();
const route = useRoute();

const data = reactive({
  form:{
    project_id: "",
    project_name: "",
    view_id: 0,
    view_name: "",
    contact_type: 0,
    contact_type_name: "",
    contact_type_other: "",
    contact_method:0,
    contact_method_name:"",
    details: "",
    file_name: "",
    file_conttents:"",
  }
});
const {form} = toRefs(data);

form.value.project_name = computed(() => bottomInfo.value.project_name);
const viewList = ref([
  {
    "id": 0,
    "name": "選択してください"
  },
  {
    "id": 1,
    "name": "プロジェクト一覧"
  },
  {
    "id": 2,
    "name": "プロジェクト新規作成"
  },
  {
    "id": 3,
    "name": "プロジェクト詳細"
  },
  {
    "id": 4,
    "name": "顔画像アップロード"
  },
  {
    "id": 5,
    "name": "イベント画像アップロード"
  },
  {
    "id": 6,
    "name": "イベント一覧表示"
  },
  {
    "id": 7,
    "name": "顔画像の確認・整理・追加"
  },
  {
    "id": 8,
    "name": "追加顔画像の確認・整理"
  },
  {
    "id": 9,
    "name": "イベント画像の確認・整理"
  },
  {
    "id": 10,
    "name": "アルバム用イベント画像確認"
  },
  {
    "id": 11,
    "name": "顔マッチング条件設定とマッチング処理"
  },
  {
    "id": 12,
    "name": "自動計算条件設定と自動計算"
  },
  {
    "id": 13,
    "name": "イベント別結果"
  },
  {
    "id": 14,
    "name": "個人別結果"
  },
  {
    "id": 15,
    "name": "結果一覧表"
  },
  {
    "id": 16,
    "name": "イベント別アルバム画像マッチング結果"
  },
  {
    "id": 17,
    "name": "個人別アルバム画像マッチング結果"
  },
  {
    "id": 18,
    "name": "アルバム画像マッチング結果（イベント画像なし）"
  },
  {
    "id": 19,
    "name": "結果ダウンロード"
  },
  {
    "id": 20,
    "name": "ユーザ作業一覧"
  },
]);

const contactTypeList = ref([
  {
    "id": 0,
    "name": "選択してください"
  },
  {
    "id": 1,
    "name": "問題が発生している"
  },
  {
    "id": 2,
    "name": "使い方で不明な部分がある"
  },
  {
    "id": 3,
    "name": "請求料金が違う"
  },
  {
    "id": 4,
    "name": "ご意見・ご希望"
  },
  {
    "id": 5,
    "name": "その他"
  }
]);

const contactMethodList = ref([
  {
    "id": 0,
    "name": "選択してください"
  },
  {
    "id": 1,
    "name": "メールで返信希望"
  },
  {
    "id": 2,
    "name": "ZOOMで対応希望"
  },
  {
    "id": 3,
    "name": "電話対応希望"
  },
  {
    "id": 4,
    "name": "対応不要"
  }
]);

const userName = computed(() => useUserStore().name);
const userInfo=JSON.parse(useUserStore().user2);
const bottomInfo = computed(() => useAppStore().bottomInfo);

const { proxy } = getCurrentInstance();
const emit = defineEmits();

const appStore = useAppStore();
const userStore = useUserStore();
const up_file_limit = 5242880;

let projectId = ref(null);


let isConfirm = ref(false);
let showViewNameError = ref(false);
let showContactTypeError = ref(false);
let showContactOtherError = ref(false);
let showContactMethodError = ref(false);
let showDetailsError = ref(false);

onMounted(() => {
  projectId.value=cache.local.get("projectId");
});

function changeView()
{
  if (form.value.view_id == 0)
  {
    showViewNameError.value = true;
    return;
  }
  else {
    showViewNameError.value = false;
    viewList.value.forEach(item => {
      if (item.id == form.value.view_id)
      {
        form.value.view_name = item.name;
        return;
      }
    });
  }
}

function changeCantactType()
{
  if (form.value.contact_type == 0)
  {
    showContactTypeError.value = true;
    return;
  }
  else {
    showContactTypeError.value = false;
    contactTypeList.value.forEach(item => {
      if (item.id == form.value.contact_type)
      {
        form.value.contact_type_name = item.name;
        return;
      }
    });
  }
}

function changeCantactMethod()
{
  if (form.value.contact_method == 0)
  {
    showContactMethodError.value = true;
    return;
  }
  else {
    showContactMethodError.value = false;
    contactMethodList.value.forEach(item => {
      if (item.id == form.value.contact_method)
      {
        form.value.contact_method_name = item.name;
        return;
      }
    });
  }
}

function selectedFile()
{
  const fileElem = document.getElementById("inquiry_file");

  if (fileElem) {
    fileElem.click();
  }

}

function changeFile(e)
{
  const file = e.target.files[0];
  console.log(file);

  if (file.size > up_file_limit)
  {
    document.getElementById("inquiry_file").value="";
    proxy.$modal.msgError("送信するファイルは5MBまでです。");
    return;
  }

  // Encode the file using the FileReader API
  const reader = new FileReader();
  reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');


      form.value.file_contents = base64String;
      

      //console.log(base64String);
      // Logs wL2dvYWwgbW9yZ...
  };
  reader.readAsDataURL(file);
  form.value.file_name = file.name;
}

function resetShowError()
{

  showViewNameError.value = false;
  showPhoneError.value = false;
  showContactTypeError.value = false;
  showContactOtherError.value = false;
  showContactMethodError.value = false;
  showDetailsError = false;
}

function confirmSubmission()
{
  let hasError = false;
  resetShowError();
  if (form.value.view_id == 0)
  {
    showViewNameError.value = true;
    hasError = true;
  }

  if (form.value.contact_type == 0)
  {
    showContactTypeError.value = true;
    hasError = true;
  }

  if (form.value.contact_type == 5 && form.value.contact_type_other == "")
  {
    showContactOtherError.value = true;
    hasError = true;
  }

  if (form.value.contact_method == 0)
  {
    showContactMethodError.value = true;
    hasError = true;
  }

  if (form.value.details.length == 0)
  {
    showDetailsError.value = true;
    hasError = true;
  }

  if (hasError)
  {
    return;
  }

  isConfirm.value = true;

  
}

function clearForm()
{
  form.value={
    project_name: bottomInfo.value.project_name,
    view_id: 0,
    contact_type: 0,
    contact_type_other: "",
    contact_method:0,
    details: "",
    file_name: "",
    file_contents: "",
  };

  resetShowError();

  document.getElementById("inquiry_file").value="";

}

function returnForm()
{
  isConfirm.value = false;
}

function sendForm()
{
  startLoading();
  let params = {};
  params.project_id = projectId.value;
  params.view_id = form.value.view_id;
  params.contact_type = form.value.contact_type;
  params.contact_type_other = form.value.contact_type_other;
  params.contact_method = form.value.contact_method;
  params.details = form.value.details;
  params.file_name = form.value.file_name;
  params.file_contents = form.value.file_contents;
  inquiryMemberApi(
    params,
    function (res) {
      proxy.$modal.msgSuccess(res.message);
      clearForm();
      closeLoading();
      emit("closeEvent");
    },
    function () {
      closeLoading();
    }
  );

}

let loading=null;
function startLoading() {
  if(loading==null){
    loading = ElLoading.service({
      lock: true,
      text: "loading",
      background: "rgba(0, 0, 0, 0.7)"
    }) 
  }

}

function closeLoading(){
  if(loading){
    loading.close();
    loading=null;
  }
}

</script>

<style lang='scss' scoped>
@import "@/assets/styles/index.css";
@import "@/assets/styles/inquiry.css";

</style>
