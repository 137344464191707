<template>
  <div>
    <div v-if="flag && !item.hidden">
      <template
        v-if="
          hasOneShowingChild(item.children, item) &&
          (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
          !item.alwaysShow
        "
      >
        <app-link
          @click="click2(item)"
          v-if="onlyOneChild.meta"
          :to="resolvePath(onlyOneChild.path, onlyOneChild.query)"
        >
          <el-menu-item
            :index="resolvePath(onlyOneChild.path)"
            :class="{ 'submenu-title-noDropdown': !isNest }"
            style="
              padding: 4px 10px 4px 18px;
              font-size: 14px;
              height: initial;
              line-height: 22px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
            "
          >
            <svg-icon
              :icon-class="
                onlyOneChild.meta.icon || (item.meta && item.meta.icon)
              "
              style="font-size: 8px; margin-right: 4px; margin-top: 6px"
            />
            <template #title
              ><span
                class="menu-title"
                :title="hasTitle(onlyOneChild.meta.help)"
                >{{ onlyOneChild.meta.title }}</span
              ></template
            >
          </el-menu-item>
        </app-link>
      </template>

      <div v-else ref="subMenu" style="margin-bottom: 20px">
        <div class="nest-menu">
          <li
            class="el-menu-item"
            role="menuitem"
            tabindex="-1"
            style="
              padding-left: 12px;
              font-size: 15px;
              font-weight: bold;
              line-height: 1.3;
              padding: 4px 10px;
              height: initial;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
            "
          >
            <svg-icon
              :icon-class="item.meta && item.meta.icon"
              style="margin-right: 8px; margin-top: 3px"
            />
            <span class="menu-title" :title="item.meta.help" :class="item.meta.class">{{
              item.meta.title
            }}</span>
          </li>
        </div>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />
      </div>
    </div>
    <div v-if="!flag && !item.hidden">
      <template
        v-if="
          hasOneShowingChild(item.children, item) &&
          (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
          !item.alwaysShow
        "
      >
        <app-link
          v-if="onlyOneChild.meta"
          :to="resolvePath(onlyOneChild.path, onlyOneChild.query)"
        >
          <el-menu-item
            :index="resolvePath(onlyOneChild.path)"
            :class="{ 'submenu-title-noDropdown': !isNest }"
          >
            <svg-icon
              :icon-class="
                onlyOneChild.meta.icon || (item.meta && item.meta.icon)
              "
            />
            <template #title
              ><span
                class="menu-title"
                :title="hasTitle(onlyOneChild.meta.help)"
                >{{ onlyOneChild.meta.title }}</span
              ></template
            >
          </el-menu-item>
        </app-link>
      </template>

      <el-sub-menu
        v-else
        ref="subMenu"
        :index="resolvePath(item.path)"
        popper-append-to-body
      >
        <template v-if="item.meta" #title>
          <svg-icon :icon-class="item.meta && item.meta.icon" />
          <span class="menu-title" :title="hasTitle(item.meta.help)">{{
            item.meta.title
          }}</span>
        </template>

        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />
      </el-sub-menu>
    </div>
  </div>
</template>

<script setup>
import { isExternal } from "@/utils/validate";
import AppLink from "./Link";
import { getNormalPath } from "@/utils/common";

const props = defineProps({
  // route object
  item: {
    type: Object,
    required: true,
  },
  isNest: {
    type: Boolean,
    default: false,
  },
  basePath: {
    type: String,
    default: "",
  },
  flag: {
    type: Boolean,
    default: true,
  },
});

const onlyOneChild = ref({});

function hasOneShowingChild(children = [], parent) {
  if (!children) {
    children = [];
  }
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false;
    } else {
      // Temp set(will be used if only has one showing child)
      onlyOneChild.value = item;
      return true;
    }
  });

  // When there is only one child router, the child router is displayed by default
  if (showingChildren.length === 1) {
    return true;
  }

  // Show parent if there are no child router to display
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: "", noShowingChildren: true };
    return true;
  }

  return false;
}

function resolvePath(routePath, routeQuery) {
  if (isExternal(routePath)) {
    return routePath;
  }
  if (isExternal(props.basePath)) {
    return props.basePath;
  }
  if (routeQuery) {
    let query = JSON.parse(routeQuery);
    return {
      path: getNormalPath(props.basePath + "/" + routePath),
      query: query,
    };
  }
  return getNormalPath(props.basePath + "/" + routePath);
}

function hasTitle(title) {
  if (title.length > 5) {
    return title;
  } else {
    return "";
  }
}

function click2(path){
  console.log(path);
}
</script>
<style lang="scss" scoped>
.router-link-active {
  background-color: #ffffff !important;
  .menu-title {
    color: #1a1a1a !important;
  }
}
.menu-title {
  font-size: 15px;
  line-height: 1.3;
}
</style>