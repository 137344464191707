import { createWebHistory, createRouter } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: '/authentication',
    component: () => import('@/views/authentication'),
    hidden: true
  },
  {
    path:'/reset-password/:path(.*)',
    component: () => import('@/views/confirmPassword'),
    hidden: true
  },
  {
    path:'/register/verified',
    component: () => import('@/views/registerResult'),
    hidden: true
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/projectList',
    component: () => import('@/views/projectList'),
    hidden: true
  },
  {
    path: '/uploadChoose',
    component: () => import('@/views/uploadChoose'),
    hidden: true
  },
  {
    path: '/upload2',
    component: () => import('@/views/upload2'),
    hidden: true
  },
  {
    path:'/sub_password/:path(.*)',
    component: () => import('@/views/sub_password'),
    hidden: true
  },
  {
    path: '/subRegister',
    component: () => import('@/views/subRegister'),
    hidden: true
  },
  {
    path: '/viewer_login',
    component: () => import('@/views/viewer_login'),
    hidden: true
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

export default router;
