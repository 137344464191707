<template>
  <div>
    <div class="navbar">
      <div class="left">
        <div class="logo"></div>
        <div class="text"><breadcrumb /></div>
      </div>
      <div class="right">
        <div style="margin-right: 24px; font-size: 14px;">
          <button class="el-link el-link--default -bordered -inverted -reload" type="button" @click="reloadPage"><span class="el-link__inner">画面更新</span></button>
        </div>
        <div style="margin-right: 24px; font-size: 14px" v-if="isViewer==0">
          <el-link :underline="false" @click="goProjectList" class="-bordered"
            >プロジェクト一覧</el-link
          >
        </div>
        <div style="margin-right: 24px; font-size: 14px" v-if="isViewer==0">
          <el-link :underline="false" @click="moveLink('https://help.superalbum.jp/help/')"><img src="@/assets/icons/svg/help_1.svg" class="icon_help">ご利用方法</el-link>
        </div>
        <div style="margin-right: 24px; font-size: 14px" v-if="isViewer==0">
          <el-link :underline="false" @click=""
            >価格表</el-link>
        </div>
        <div style="margin-right: 20px; font-size: 14px" v-if="isViewer==0">
          <el-link :underline="false" @click="openContact"
            >お問合せ</el-link>
        </div>
        <div style="margin-right: 20px; font-size: 14px">
          <!-- <el-popover
            placement="bottom-start"
            content="この機能はただいまご利用できません"
            popper-class="cus_tooltip"
            :width="270"
            trigger="hover"
          > 
            <template #reference>
            </template>
           </el-popover> -->
        </div>
        <div style="margin-right: 8px;">
          <el-link :underline="false" @click="switchVersion" class="el-link--image" v-if="subNum>0">
          <!-- <img v-if="workSub==0&&workSub!=isSub&&route.path!='/projectList'" class="user-avatar small" src="@/assets/images/user.png">
          <img v-else-if="workSub==1&&workSub!=isSub&&route.path!='/projectList'" class="user-avatar small" src="@/assets/images/avatar_sub1.png">
          <img v-else-if="workSub==2&&workSub!=isSub&&route.path!='/projectList'" class="user-avatar small" src="@/assets/images/avatar_sub2.png">
          <img v-else-if="workSub==3&&workSub!=isSub&&route.path!='/projectList'" class="user-avatar small" src="@/assets/images/avatar_sub3.png">
          <img v-else-if="workSub==4&&route.path!='/projectList'" class="user-avatar small" src="@/assets/images/avatar_multiple.png"> -->
          <img v-if="isSub==0&&route.path!='/projectList'" class="user-avatar small" :class="workSub==0?'inactive':''" src="@/assets/images/avatar_multiple.png">
          <img v-if="isSub!=0&&route.path!='/projectList'" class="user-avatar small" :class="workSub==4?'inactive':''" src="@/assets/images/user.png">
          </el-link>
        </div>
        <div class="avatar-container">
          <el-dropdown
            @command="handleCommand"
            placement="bottom-end"
            class="right-menu-item hover-effect"
            trigger="hover"
          >
            <div class="avatar-wrapper">
              <img v-if="isViewer==1" src="@/assets/images/avatar_viewonly.png" class="user-avatar" />
              <img v-else-if="isSub==1" src="@/assets/images/avatar_sub1.png" class="user-avatar" />
              <img v-else-if="isSub==2" src="@/assets/images/avatar_sub2.png" class="user-avatar" />
              <img v-else-if="isSub==3" src="@/assets/images/avatar_sub3.png" class="user-avatar" />
              <img v-else src="@/assets/images/user.png" class="user-avatar" />
              <div class="text1" v-if="isViewer==1 || isSub == 0">{{ userName }}</div>
              <div class="text1" v-else-if="isSub>0">サブユーザ {{ isSub }}</div>
              <img src="@/assets//images/user2.png" class="user-avatar2" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="userInfoChange" v-if="master_flag>0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">ユーザー情報変更</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="emailChange" v-if="master_flag!=0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">メール変更</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="pwdChange" v-if="isViewer==0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">パスワード変更</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="logout">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">ログアウト</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="terms" v-if="isViewer==0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">ご利用規約</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="privacy" v-if="isViewer==0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">プライバシーポリシー</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="withdrawal" v-if="master_flag!=0">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../assets/images/user3.png"
                      style="width: 8px; height: 8px"
                    />
                    <div style="margin-left: 8px">退会</div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import es, { ElMessageBox } from "element-plus";
import Breadcrumb from "@/components/Breadcrumb";
import ScrollView from "@/components/ScrollView/index.vue";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import { computed } from "vue";
import {
  selectVersion,
  selectMainVersion,
  selectShareVersion
} from "@/api/projectList";
import cache from "@/plugins/cache";
const router = useRouter();
const route = useRoute();
const userName = computed(() => useUserStore().name);
const { proxy } = getCurrentInstance();
const emit = defineEmits();

const appStore = useAppStore();
const userStore = useUserStore();
const projectInfo = computed(() => appStore.projectInfo);

const showNoLogin = ref(false);
const showLogin = ref(false);

let termsOfUseDialogVisible = ref(false);
let policyDialogVisible = ref(false);
let usageDialogVisible = ref(false);

const props = defineProps({
  isSub: {
    type: String,
    default: "",
  },
  workSub:{
    type: String,
    default: "",
  },
  master_flag:{
    type: String,
    default: "",
  },
  isViewer:{
    type: String,
    default: "",
  },
});
let subNum = cache.local.get("subNum");
const isSub = computed(() => props.isSub);
const workSub = computed(()=> props.workSub);
const master_flag = computed(() => props.master_flag);
const isViewer = computed(() => props.isViewer);

function toggleSideBar() {
  appStore.toggleSideBar();
}

function handleCommand(command) {
  switch (command) {
    case "logout":
      logout();
      break;
    case "userInfoChange":
      emit("showUserInfoChange");
      break;
    case "emailChange":
      emit("showEmailChange");
      break;
    case "pwdChange":
      emit("showPwdChange");
      break;
    case "withdrawal":
      emit("withDrawalEvent");
      break;
    case "terms":
      emit("showTermsOfUse");
      break;
    case "privacy":
      emit("showPolicy");
      break;
    default:
      break;
  }
}

function logout() {
  let go_url = "/login";
  if (isViewer.value == 1)
  {
    go_url = "/viewer_login";
  }
  ElMessageBox.confirm("作業を終了して、ログアウトしますか？", "ログアウト", {
    confirmButtonText: "ログアウト",
    cancelButtonText: "キャンセル",
    type: "warning",
  })
  .then(() => {
      userStore
        .logOut()
        .then(() => {
          location.href = go_url;
        })
        .catch(() => {
          location.href = go_url;
        });
  })
  .catch(() => {});
}

function goProjectList() {
  router.push("/projectList");
}

function showUsage() {
  window.open("/help/index.html", "_blank");
  // emit("showUsage");
}

function showTermsOfUse() {
  emit("showTermsOfUse");
}

function showPrice() {
  emit("showTermsOfUse");
}

function showPolicy() {
  emit("showPolicy");
}

function openContact() {
  //window.open("https://www.whoselab.com/contact.html", "_blank");
  emit("showInquiry");
}

function reloadPage(){
  window.location.href=window.location.href;
}

function moveLink (url) {
  proxy.$modal.openHelp(url);
}

function switchVersion() {
  console.log('isSub =' + isSub);
  if (isSub.value==0) {
    if (workSub.value != 0) {
      backOwnVersion();
    }
    else {
      goShareVersion();
    }
  }
  else {
    if (workSub.value != 4) {
      backOwnVersion();
    }
    else {
      goMainVersion();
    }
  }
}

function goShareVersion() {
  let params = {};
  params.project_id = projectInfo.value.project_id;
  selectShareVersion(params,function(res){
    proxy.$modal.msgSuccess(res.message);
    reloadPage();

  },function(){

  });
}

function goMainVersion() {
  let params = {};
  params.project_id = projectInfo.value.project_id;
  selectMainVersion(params,function(res){
    proxy.$modal.msgSuccess(res.message);
    reloadPage();

  },function(){

  });
}

function backOwnVersion() {
  //startLoading();
  let params = {};
  params.version_id = 0;
  params.project_id = projectInfo.value.project_id;
  selectVersion(params,function(res){
    proxy.$modal.msgSuccess(res.message);
    reloadPage();

  },function(){

  });
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/index.css";
.navbar {
  .el-link {
    &.el-link--image{
      &:after {
        *background-image: url("@/assets/icons/svg/delete.svg");
      }
      .inactive {
        opacity: 0.5;
      }
    }
  }
  .el-link.-bordered.-reload:hover:before {
    background-image: url("@/assets/icons/svg/icon_reload_on.svg");
  }

  .el-link.-bordered.-reload:before {
    background-image: url("@/assets/icons/svg/icon_reload.svg");
  }

  .left {
    .logo {
      background-image: url("@/assets/images/logo.png");
    }
  }
}

.icon_help {
  width: 20px;
  cursor: pointer;
}
</style>
