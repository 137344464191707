import { login, logout, login2, logout2 } from '@/api/login'
import { getToken, setToken, removeToken,getRefreshToken, setRefreshToken, removeRefreshToken,getUser,setUser,removeUser,getUserName,setUserName,removeUserName} from '@/utils/auth'
import cache from "@/plugins/cache";

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      refresh_token:getRefreshToken(),
      name: getUserName(),
      user2: getUser(),
    }),
    actions: {
      login(userInfo) {
        const project_id = userInfo.project_id;
        const email = userInfo.email;
        const password = userInfo.password;
        return new Promise((resolve, reject) => {
          login(project_id, email, password).then(res => {
            setToken(res.api_token);
            setRefreshToken(res.refresh_token);
            setUserName(res.user.user_name);
            setUser(JSON.stringify(res.user));
            this.token = res.api_token;
            this.refresh_token = res.refresh_token;
            this.name=res.user.user_name;
            this.user2=JSON.stringify(res.user);
            cache.local.set("isViewer", 0);
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      logOut() {
        return new Promise((resolve, reject) => {
          logout(this.token).then(() => {
            this.token = ''
            this.refresh_token = "";
            this.name="";
            this.user2=null;
            removeToken();
            removeRefreshToken();
            removeUserName();
            removeUser();
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      view_login(userInfo) {
        const login_id = userInfo.login_id;
        const password = userInfo.password;
        return new Promise((resolve, reject) => {
          login2(login_id, password).then(res => {
            setToken(res.api_token);
            setRefreshToken(res.refresh_token);
            setUserName(res.user.login_id);
            setUser(JSON.stringify(res.user));
            this.token = res.api_token;
            this.refresh_token = res.refresh_token;
            this.name=res.user.login_id;
            this.user2=JSON.stringify(res.user);
            cache.local.set("projectId", res.user.project_id);
            cache.local.set("versionId", res.user.version_id);
            cache.local.set("isViewer", 1);
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      refreshUserName(name){
        this.name=name;
      }
    }
  })

export default useUserStore
