<template>
     <el-dialog 
        title="パスワード変更" 
        :model-value="pwdChange" 
        width="480px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="handleClose"
     >
      <div class="pwd-change-wrap">
        <!--div class="pwd-change-r1">
          <!div class="pwd-change-r1-left">ログインID</div>
          <!div class="pwd-change-r1-right">{{userInfo.login_id}}</div>
        </div-->
        <div class="pwd-change-r1" style="margin-top: 3px">
          <div class="pwd-change-r1-left">メールアドレス</div>
          <div class="pwd-change-r1-right">{{userInfo.email}}</div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 6px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">現在のパスワード</div>
            <div class="pwd-change-r2-top-right">
              <el-input
                v-model="pwdChangeForm.password"
                type="password"
                class="cus-input2"
                v-if="closeEyes"
              />
              <el-input
                v-model="pwdChangeForm.password"
                type="text"
                class="cus-input2"
                v-if="showEyes"
              />
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeInput"
                v-if="showEyes"
              />
              <img
                src="@/assets/icons/svg/eyes2.svg"
                class="eyesStyle"
                @click="changeInput"
                v-if="closeEyes"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 8px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">新パスワード</div>
            <div class="pwd-change-r2-top-right">
              <el-input
                v-model="pwdChangeForm.new_password"
                type="password"
                class="cus-input2"
                v-if="closeEyes1"
              />
              <el-input
                v-model="pwdChangeForm.new_password"
                type="text"
                class="cus-input2"
                v-if="showEyes1"
              />
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeInput1"
                v-if="showEyes1"
              />
              <img
                src="@/assets/icons/svg/eyes2.svg"
                class="eyesStyle"
                @click="changeInput1"
                v-if="closeEyes1"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r2" style="margin-top: 8px">
          <div class="pwd-change-r2-top">
            <div class="pwd-change-r2-top-left">新パスワード確認用</div>
            <div class="pwd-change-r2-top-right">
              <el-input
                v-model="pwdChangeForm.new_password_confirmation"
                type="password"
                class="cus-input2"
                v-if="closeEyes2"
              />
              <el-input
                v-model="pwdChangeForm.new_password_confirmation"
                type="text"
                class="cus-input2"
                v-if="showEyes2"
              />
            </div>
          </div>
          <div class="pwd-change-r2-bottom">
            <div>
              <img
                src="@/assets/icons/svg/eyes.svg"
                class="eyesStyle"
                @click="changeInput2"
                v-if="showEyes2"
              />
              <img
                src="@/assets/icons/svg/eyes2.svg"
                class="eyesStyle"
                @click="changeInput2"
                v-if="closeEyes2"
              />
            </div>
            <div style="font-size: 12px">パスワードを表示する</div>
          </div>
        </div>
        <div class="pwd-change-r3-bottom">
          <div class="pwd-change-r3-btn">
            <div><el-button class="left-btn" @click="handleClose">キャンセル</el-button></div>
            <div><el-button class="right-btn" @click="handleUpdate">変更</el-button></div>
          </div>
        </div>
      </div>
    </el-dialog>
</template>
<script setup>
import {editUserPassword} from "@/api/projectList";
import { getUser} from '@/utils/auth';
import { ElLoading } from 'element-plus';
const { proxy } = getCurrentInstance();
const userInfo=JSON.parse(getUser());

const pwdChange = ref(false);
defineExpose({getList});
const showEyes = ref(false);
const closeEyes = ref(true);
const showEyes1 = ref(false);
const closeEyes1 = ref(true);
const showEyes2 = ref(false);
const closeEyes2 = ref(true);
const pwdChangeForm = ref({
  password: "",
  new_password: "",
  new_password_confirmation: "",
});

let loading=null;
function startLoading() {
  if(loading==null){
    loading = ElLoading.service({
      lock: true,
      text: "loading",
      background: "rgba(0, 0, 0, 0.7)"
    }) 
  }

}

function closeLoading(){
  if(loading){
    loading.close();
    loading=null;
  }
}

function changeInput() {
  if (showEyes.value) {
    showEyes.value = false;
    closeEyes.value = true;
  } else {
    showEyes.value = true;
    closeEyes.value = false;
  }
}
function changeInput1() {
  if (showEyes1.value) {
    showEyes1.value = false;
    closeEyes1.value = true;
  } else {
    showEyes1.value = true;
    closeEyes1.value = false;
  }
}
function changeInput2() {
  if (showEyes2.value) {
    showEyes2.value = false;
    closeEyes2.value = true;
  } else {
    showEyes2.value = true;
    closeEyes2.value = false;
  }
}
function handleClose() {
    pwdChangeForm.value={
        password: "",
        new_password: "",
        new_password_confirmation: "",
    }
    pwdChange.value = false;
}
//update
function handleUpdate(){
  startLoading();
  // alert( JSON.stringify(pwdChangeForm.value));
  editUserPassword(pwdChangeForm.value,function(res) {
    proxy.$modal.msgSuccess(res.message);
    handleClose();
    closeLoading();
  },function(){
    closeLoading();
  })
}

function getList(id) {
  // if(id){
  //   alert('getList---'+id)
  // }
  pwdChange.value = true;
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/general.scss";
.pwd-change-wrap {
  width: 440px;
  height: 347px;
  margin: 0 auto;
  .pwd-change-r1 {
    width: 100%;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .pwd-change-r1-left {
      width: 158px;
      font-size: 16px;
      color: #1a1a1a;
      line-height: 21px;
    }
    .pwd-change-r1-right {
      font-size: 14px;
      line-height: 21px;
      color: #1a1a1a;
    }
  }
  .pwd-change-r2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 72px;
    .pwd-change-r2-top {
      height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .pwd-change-r2-top-left {
        width: 158px;
      }
      .pwd-change-r2-top-right {
        width: 282px;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .pwd-change-r2-bottom {
      margin-top: 4px;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-right: 24px;
      .eyesStyle {
        vertical-align: middle;
        margin-top: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
  .pwd-change-r3-bottom {
    margin-top: 32px;
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .pwd-change-r3-btn {
      width: 256px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left-btn {
        width: 120px;
        height: 30px;
        border: 2px solid #656565;
        font-size: 14px;
        color: #656565;
        background: #ffffff;
      }
      .right-btn {
        width: 120px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
        background: #12678d;
      }
    }
  }
}
</style>
