<template>
  {{title}}
</template>

<script setup>
const route = useRoute();
let title=ref(null);
watchEffect(() => {
  title.value=route.matched.filter(item => item.children.length==0)[0].meta.title;
})
title.value=route.matched.filter(item => item.children.length==0)[0].meta.title;
</script>

<style lang='scss' scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>