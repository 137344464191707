import request from '@/utils/request'

export function login(project_id, email, password) {
  const data = {
    project_id,
    email,
    password
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function refreshToken() {
  return request({
    url: '/refresh_token',
    method: 'post'
  })
}

export function userRegister(data){
  return request({
    url: '/register',
    method: 'post',
    data:data
  })
}

export function forgotLoginId(data){
  return request({
    url: '/forgot_login_id',
    method: 'post',
    data:data
  })
}

export function forgotPassword(data){
  return request({
    url: '/forgot_password',
    method: 'post',
    data:data
  })
}

export function resetPassword(data){
  return request({
    url: '/reset_password',
    method: 'post',
    data:data
  })
}

export function resetSubPassword(data){
  return request({
    url: '/set_sub_password',
    method: 'post',
    data:data
  })
}

export function userSubRegister(data){
  return request({
    url: '/sub_register',
    method: 'post',
    data:data
  })
}

export function verify(data){
  return request({
    url: '/verify',
    method: 'post',
    data:data
  })
}

export function verifyChangeMail(data){
  return request({
    url: '/verify_change_mail',
    method: 'post',
    data:data
  })
}

export function sendCode(data){
  return request({
    url: '/send_code',
    method: 'post',
    data:data
  })
}

export function login2(login_id, password) {
  const data = {
    login_id,
    password
  }
  return request({
    url: '/result_view_login',
    method: 'post',
    data: data
  })
}

export function logout2() {
  return request({
    url: '/result_view_logout',
    method: 'post'
  })
}