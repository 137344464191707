import { constantRoutes } from '@/router'
import menuJson0 from '@/api/menu0.json';
import menuJson1 from '@/api/menu1.json';
import menuJson2 from '@/api/menu2.json';
import menuJson34 from '@/api/menu34.json';
import menuJson56 from '@/api/menu56.json';
import menuJson99 from '@/api/menu99.json';
import menuJsonSub from '@/api/menuSub.json';
import menuJsonSubOther from '@/api/menuSubOther.json';
import menuJsonViewer from '@/api/menuViewer.json';
import Layout from '@/layout/index'
import cache from '@/plugins/cache';

const modules = import.meta.glob('./../../views/**/*.vue')

const usePermissionStore = defineStore(
  'permission',
  {
    state: () => ({
      sidebarRouters: []
    }),
    actions: {
      setSidebarRouters(routes) {
        this.sidebarRouters = routes
      },
      generateRoutes() {
        return new Promise(resolve => {
          let isSub = cache.local.get("isSub");
          let workSub = cache.local.get("workSub");
          let shared_flag=cache.local.get("sharedFlag");
          let isViewer = cache.local.get("isViewer");
          let menuList = menuJson99;

          if (isViewer == 1) {
            menuList = menuJsonViewer;
          }
          else {
            if (isSub>0 || (isSub == 0 && workSub > 0)) {
              if (isSub != workSub && shared_flag==0)
              {
                menuList = menuJsonSubOther;
              }
              else {
                menuList = menuJsonSub;
              }
              
            } else {
              menuList = menuJson99;
            }
          }

          let data = JSON.stringify(menuList);
          const sdata = JSON.parse(data)
          const rdata = JSON.parse(data)
          const sidebarRoutes = filterAsyncRouter(sdata)
          const rewriteRoutes = filterAsyncRouter(rdata, true)
          this.setSidebarRouters(constantRoutes.concat(sidebarRoutes))
          resolve(rewriteRoutes)
        })

      }
    }
  })

function handleMenuJson(progress_flag) {
  if (progress_flag == 0) {
    return menuJson0;
  }
  if (progress_flag == 1) {
    return menuJson1;
  }
  if (progress_flag == 2) {
    return menuJson2;
  }
  if (progress_flag == 3) {
    return menuJson34;
  }
  if (progress_flag == 4) {
    return menuJson34;
  }
  if (progress_flag == 5) {
    return menuJson56;
  }
  if (progress_flag == 6) {
    return menuJson56;
  }
}

function filterAsyncRouter(asyncRouterMap, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = Layout
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view) => {
  let res;
  for (const path in modules) {
    const dir = path.split('views/')[1].split('.vue')[0];
    if (dir === view) {
      res = () => modules[path]();
    }
  }
  return res;
}

export default usePermissionStore
