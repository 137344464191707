<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <!-- <keep-alive> -->
        <component :is="Component" :key="route.path" />
      <!-- </keep-alive> -->
    </router-view>
  </section>
</template>

<script setup>
const route = useRoute();
</script>

<style lang="scss" scoped>
.app-main {
  width: 100%;
  position: relative;
  overflow: initial;
}
</style>